var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    _vm._l(Object.keys(_vm.sections).sort(), function(name) {
      return _c(
        "div",
        [
          _vm.sections[name].length > 0
            ? _c("h5", [_vm._v(_vm._s(name))])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.sections[name], function(l) {
            return [
              l.type == "list"
                ? [
                    l.text.length > 3
                      ? _c(
                          "Flipper",
                          { attrs: { title: "...", shy: true } },
                          _vm._l(l.text, function(item) {
                            return _c("div", {
                              staticClass: "item",
                              domProps: { innerHTML: _vm._s(item) }
                            })
                          }),
                          0
                        )
                      : _vm._l(l.text, function(item) {
                          return _c("div", {
                            staticClass: "item",
                            domProps: { innerHTML: _vm._s(item) }
                          })
                        })
                  ]
                : _c("div", {
                    class: l.type,
                    domProps: { innerHTML: _vm._s(l.text) }
                  })
            ]
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }