var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.total > _vm.perPage
    ? _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
        _c("div", {}, [
          _c(
            "ul",
            {
              staticClass: "pagination pagination-sm",
              staticStyle: { margin: "0" }
            },
            [
              _vm.current == 1
                ? _c("li", { staticClass: "disabled" }, [
                    _c("a", [_vm._v("«")])
                  ])
                : _c("li", { staticClass: "cursor-pointer" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.setPage(_vm.current - 1)
                          }
                        }
                      },
                      [_vm._v("«")]
                    )
                  ]),
              _vm._v(" "),
              _vm._l(_vm.pages, function(p) {
                return [
                  p == _vm.current
                    ? _c("li", { staticClass: "active" }, [
                        _c("span", [_vm._v(_vm._s(p))])
                      ])
                    : _c("li", { staticClass: "cursor-pointer" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.setPage(p)
                              }
                            }
                          },
                          [_vm._v(_vm._s(p))]
                        )
                      ])
                ]
              }),
              _vm._v(" "),
              _vm.current == _vm.last
                ? _c("li", { staticClass: "disabled" }, [
                    _c("a", [_vm._v("»")])
                  ])
                : _c("li", { staticClass: "cursor-pointer" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.setpage(_vm.current + 1)
                          }
                        }
                      },
                      [_vm._v("»")]
                    )
                  ])
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }