var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-2" }, [
        _c("div", { staticClass: "faux-label" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-10" }, [
        _c("div", { staticClass: "faux-input" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }