var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.long
        ? _c(
            "span",
            { staticClass: "click", on: { click: _vm.showFullDiff } },
            [
              _c("StrDiff", {
                attrs: {
                  oldStr: _vm.oldStr.substr(0, 100),
                  newStr: _vm.newStr.substr(0, 100)
                }
              }),
              _vm._v("\n     …\n  ")
            ],
            1
          )
        : _c("StrDiff", { attrs: { oldStr: _vm.oldStr, newStr: _vm.newStr } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }