var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    attrs: { type: "hidden", name: _vm.name, id: _vm.id },
    domProps: { value: _vm.value }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }