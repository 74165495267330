define([
        "moment", "backbone","jquery","underscore","common","permission", "darsan",
    ],
    function(moment, Backbone, $, _, common, perm, darsan)
    {
        Backbone.ModelEx = Backbone.Model.extend({

            fetchm: function(options)
            {
                options = options || {};
                var d = $.Deferred();
                options.success = function(model, response, options)
                {
                    d.resolve(model,response,options);
                };
                options.error = function(model, response, options)
                {
                    d.reject(model, response, options);
                }
                Backbone.Model.prototype.fetch.call(this, options);
                return d.promise();
            },
            
            fetch: function(options)
            {
              return this.fetchm(options).fail((model, resp, options) => darsan.err(resp))
            },

            savem: function(attrs, options)
            {
                options = options || {};
                attrs = attrs || {};
                var d = $.Deferred();
                options.success = function(model, response, options)
                {
                    d.resolve(model,response,options);
                };
                options.error = function(model, response, options)
                {
                    d.reject(model, response, options);
                }
                Backbone.Model.prototype.save.call(this, attrs, options);
                return d.promise();
            },

            save: function(attr, options)
            {
              return this.savem(attr, options).fail((model, resp, options) => darsan.err(resp))
            },

            destroym: function(options)
            {
                options = options || {};
                var d = $.Deferred();
                options.success = function(model, response, options)
                {
                    d.resolve(model,response,options);
                };
                options.error = function(model, response, options)
                {
                    d.reject(model, response, options);
                }
                Backbone.Model.prototype.destroy.call(this, options);
                return d.promise();
            },

            destroy: function(attr, options)
            {
              return this.destroym(attr, options).fail((model, resp, options) => darsan.err(resp))
            },
        });


        Backbone.CollectionEx = Backbone.Collection.extend({

            fetchm: function(options)
            {
                options = options || {};
                var d = $.Deferred();
                options.success = function(model, response, options)
                {
                    d.resolve(model,response,options);
                };
                options.error = function(model, response, options)
                {
                    d.reject(model, response, options);
                }
                Backbone.Collection.prototype.fetch.call(this, options);
                return d.promise();
            },

            fetch: function(options)
            {
              return this.fetchm(options).fail((model, resp, options) => darsan.err(resp))
            },

        });

        Backbone.EntityModel = Backbone.ModelEx.extend({
            idAttribute: "entity",
        });

        Backbone.EntityCollection = Backbone.CollectionEx.extend({
            model: Backbone.EntityModel,
        });

        ///////////////////////////////////////////////
        Backbone.CollectionExPageable = Backbone.Collection.extend(
            {
                initialize: function(options)
                {
                    this.perPage = this.perPage || userSettings.rowsPerPage || 25;
                },

                fetchm: function(options)
                {
                    options = options || {};
                    options.data = options.data || {};

                    var d = $.Deferred();
                    options.success = function(model, response, options)
                    {
                        d.resolve(model,response,options);
                    };
                    options.error = function(model, response, options)
                    {
                        d.reject(model, response, options);
                    }

                    if (!_.has(options.data, "page") ){
                        options.data.page = 1;
                    }
                    options.data.paged = 1;
                    options.data.from = (options.data.page-1) * this.perPage;
                    options.data.count = this.perPage;
                    delete options.data.page;

                    if (options.data.sort) this.sortCrit = options.data.sort;

                    Backbone.Collection.prototype.fetch.call(this, options);
                    return d.promise();
                },

                fetch: function(options)
                {
                  return this.fetchm(options).fail((model, resp, options) => darsan.err(resp))
                },

                parse: function(response, options){
                    if(response.hasOwnProperty("total")) this.total = response.total;
                    if(response.hasOwnProperty("from")) this.page = Math.floor(response.from/this.perPage)+1;
                    if(response.hasOwnProperty("page")) this.page = response.page;
                    if(response.data) response = response.data;

                    return response;
                },

                pageInfo: function() {
                    var info = {
                        total: this.total,
                        page: this.page,
                        perPage: this.perPage,
                        pages: Math.ceil(this.total / this.perPage),
                        prev: false,
                        next: false,
                        listPrev: [],
                        listNext: [],
                        first: false,
                        last: false,
                    };

                    var max = Math.min(this.total, this.page * this.perPage);

                    if (this.total == this.pages * this.perPage) {
                        max = this.total;
                    }

                    info.range = [(this.page - 1) * this.perPage + 1, max];

                    if (this.page > 1) {
                        info.prev = this.page - 1;
                    }

                    if (this.page < info.pages) {
                        info.next = this.page + 1;
                    }

                    /// cur-100
                    if (this.page > 105 ) {
                        info.listPrev.push(this.page-100);
                    }
                    /// cur-10
                    if (this.page > 12 ) {
                        info.listPrev.push(this.page-10);
                    }
                    /// cur-2
                    if (this.page > 2 ) {
                        info.listPrev.push(this.page-2);
                    }
                    /// cur-1
                    if (this.page != 1 ) {
                        info.listPrev.push(this.page-1);
                    }
                    // First
                    if ( $.inArray( 1, info.listPrev ) == -1 && info.page != 1) {
                        info.first = 1;
                    }

                    /// cur+1
                    if ( this.page + 1 <= info.pages ) {
                        info.listNext.push(this.page+1);
                    }
                    /// cur+2
                    if ( this.page + 2 <= info.pages ) {
                        info.listNext.push(this.page+2);
                    }
                    /// cur+10
                    if ( this.page + 10 <= info.pages ) {
                        info.listNext.push(this.page+10);
                    }
                    /// cur+100
                    if ( this.page + 100 <= info.pages ) {
                        info.listNext.push(this.page+100);
                    }
                    // LAST
                    if ( $.inArray( info.pages, info.listNext )==-1 && info.page != info.pages ) {
                        info.last = info.pages;
                    }

                    return info;
                },

            });

        Backbone.PagerView = Backbone.View.extend(
            {
                initialize: function(options)
                {
                    // console.log(this);
                    this.listenTo(this.collection,"reset", this.render);
                },

                events: {
                    "click a[data-page]": "pageClick",
                },

                pageClick: function(ev)
                {
                    var page = $(ev.target).attr("data-page");
                    this.trigger("setPage",page);
                },

                render: function()
                {
                    var total = this.collection.total;
                    if (!total) {
                        return this;
                    }

                    var curr = this.collection.page;
                    var perPage = this.collection.perPage;
                    var last = Math.ceil(total/perPage);

                    this.$el.empty();

                    // if 1 page return
                    if (last <= 1) return this;

                    var prev = curr==1 ? null : curr-1;
                    var next = curr==last ? null : curr+1;

                    var pages = this._nicePages(curr, last);
                    var markup = pages.map(function(p)
                    {
                        return p==curr ? '<li class="active"><span>'+p+'</span></li>' : '<li class="cursor-pointer"><a data-page="'+p+'">'+p+'</a></li>';
                    }).join("");

                    if(curr==1){
                        markup = "<li class='disabled'><a>«</a></li>" + markup;
                    }else{
                        markup = "<li class='cursor-pointer'><a data-page='"+(curr-1)+"'>«</a></li>" + markup;
                    }
                    if(curr==last){
                        markup += "<li class='disabled'><a>»</a></li>";
                    }else{
                        markup += "<li class='cursor-pointer'><a data-page='"+(curr+1)+"'>»</a></li>";
                    }

                    this.$el.append(markup);

                    return this;
                },

                _nicePages: function(curr,last)
                {
                    curr = parseInt(curr);
                    last = parseInt(last);
                    var d = Math.floor(curr/10);
                    var h = Math.floor(curr/100);
                    var t = Math.floor(curr/1000);

                    var pages = [1,last];

                    pages = pages.concat(_.range(t-2,t).map(function(el) { return el*1000 }));
                    pages = pages.concat(_.range(h-2,h).map(function(el) { return el*100 }));
                    pages = pages.concat(_.range(d-2,d).map(function(el) { return el*10 }));
                    pages = pages.concat(_.range(curr-3,curr));
                    pages = pages.concat(_.range(curr,curr+3));
                    pages = pages.concat(_.range(t,t+2).map(function(el) { return el*1000 }));
                    pages = pages.concat(_.range(h,h+2).map(function(el) { return el*100 }));
                    pages = pages.concat(_.range(d,d+2).map(function(el) { return el*10 }));

                    return _.chain(pages)
                        .filter(function(el) { return el>0 && el<=last })
                        .sort(function(a, b) { return a-b })
                        .uniq(true)
                        .value();
                },

            });
        ///////////////////////////////////////////////

        Backbone.JoinCollection = Backbone.Collection.extend({

            fetch: function(options)
            {
                options = options ? _.clone(options) : {};
                if (options.parse === void 0) options.parse = true;

                var collection = this;
                options.success = function(resp) {
                    var method = options.reset ? 'reset' : 'set';
                    collection[method](resp, options);
                    if (success) success(collection, resp, options);
                    collection.trigger('sync', collection, resp, options);
                };
                wrapError(this, options);
                return this.sync('read', this, options);
            },
        });

        Backbone.TemplateView = Backbone.View.extend({

            template: "",

            initialize: function(options)
            {
                var options = options || {};
                this.module = options.module;
                this.compiled = _.template(this.template,null);
            },

            render: function()
            {
                var markup = this.compiled({
                    me: this.model.toJSON(),
                  common: common,
                  cooper: common.cooperMacro,
                  module: this.module,
                  moment: moment,
                  perm: perm,
                });

                this.$el.empty().append(markup);

                if (this.extraRender) this.extraRender();

                this.$el.removeClass("loading");

                return this;
            },

            isLoading: function() {
                this.$el.addClass("loading");
            }
        });

        Backbone.TemplateCollectionView = Backbone.View.extend({

            template: "",

            initialize: function()
            {
                this.compiled = _.template(this.template,null,{variable: "me"});
                this.collection.on("reset add change remove", _.bind(this.render,this));
            },

            render: function()
            {
                var markup = this.compiled({collection: this.collection});
                this.$el.empty().append(markup);
                return this;
            }
        });

        Backbone.TemplateCooperView = Backbone.TemplateView.extend({
            events: {
                'select2-opening .text-cuper': "toDataOld",
                'focus  input.text-cuper': 'toDataOld',
                'focus textarea.text-cuper': 'toDataOld',
                'cooper:stash': 'toDataOld',
                'cooper:changed': 'changeInput',
                'cooper:changedIpInput': 'changedIpInput',
                'keydown input.text-cuper': function(ev) { if (ev.keyCode === 13) {ev.preventDefault();ev.target.blur();} },
                'blur input.text-cuper': function(ev) { $(ev.target).trigger("cooper:changed") },
                'blur textarea.text-cuper': function(ev) { $(ev.target).trigger("cooper:changed") },
                'click .undo-text': "undoSave",

                'click input.checkbox-cuper': 'changeInputCheckbox',
                'click .undo-checkbox': "undoSaveCheckbox",

                'select2-focus select.select-cuper': 'toDataOld',
                'focus  select.select-cuper': 'toDataOld',
                'change select.select-cuper': 'changeInput',

                'click #deleteEntity': 'deleteEntity',
            },

            initialize: function(options)
            {
                Backbone.TemplateView.prototype.initialize.apply(this,arguments);

                // for Extend children Events
                _.extend(this.events, Backbone.TemplateCooperView.prototype.events);
                
                this.delegateEvents()
            },

            toDataOld: function(ev){
                var el = $(ev.target);
                el.attr('data-temp-val', el.val() );
            },

            changeInput: function(ev){
                var me = this;
                var el = $(ev.target);

		        ev.stopPropagation();

                if(me.collTrigger)
                {
                    me.model = me.collection.get(el.attr('data-id'));
                    me.model.url = me.model.urlOrigin + "/" + el.attr('data-id');
                }

                el.css("animation", ""); el.css("-webkit-animation", "");

                var name = el.attr('name');
                var scale = el.attr("data-scale");
                var value = el.val();

                var toSave = {};
                toSave[name] = value;
                if (scale) toSave[name] /= scale;

                // console.log(value, el.attr('data-temp-val'), value != el.attr('data-temp-val'))
                if(value != el.attr('data-temp-val') || (ev.removed && value != ev.removed.id)){
                    me.saveModel(el, toSave, name, ev);
                    me.$el.find("[data-undo='"+name+"']").removeClass('hidden');
                }
            },

            changedIpInput: function(ev, pool) {
                var me = this;
                var el = $(ev.target);

                ev.stopPropagation();

                if (me.collTrigger) {
                    me.model = me.collection.get(el.attr('data-id'));
                    me.model.url = me.model.urlOrigin + "/" + el.attr('data-id');
                }

                el.css("animation", ""); el.css("-webkit-animation", "");

                var name = el.attr('name');
                var scale = el.attr("data-scale");
                var value = el.val();

                var toSave = {};
                toSave[name] = value;
                if (scale) toSave[name] /= scale;
                if (pool) toSave['static_pool'] = pool;

                if (value != el.attr('data-temp-val') || (ev.removed && value != ev.removed.id)) {
                    me.saveModel(el, toSave, name, ev);
                    me.$el.find("[data-undo='"+name+"']").removeClass('hidden');
                }
            },

            saveModel: function(el, toSave, name, ev, el_animate){
                var me = this;

                el_animate = el_animate || el;

                if (me.collTrigger || el.attr('data-id'))
                {
                    me.model = me.collection.get(el.attr('data-id'));
                }

                var group = el.closest('.form-group');
                group.removeClass('has-error');

                if(me.model.urlRoot){
                    me.model.url = me.model.urlRoot;
                }

                name = name || el_animate.attr('name');
                var helper = me.$el.find(".help-"+name).length ?
                    me.$el.find(".help-"+name) :
                    me.$el.find("#help-"+name);

                me.model.save(toSave, {patch: true})
                    .done(function(model) {
                        helper.addClass("hidden");
                        me.animateSuccess(el_animate);

                        el.attr('data-old-val', el.attr('data-temp-val') );
                        if(ev.removed){
                            el.attr('data-old-val', ev.removed.id);
                        }

                        if (el.is(":focus")) el.blur();
                        el.trigger("cooper:save:success", {value: model.get(name), name, old: el.attr('data-old-val') });
                    })
                    .fail(function(data, xhr){
                        group.addClass('has-error');
                        var err = darsan.errorText(xhr, " ", true)

                        helper.removeClass("hidden").html(err);
                        el_animate.focus();
                        me.animateFail(el_animate);

                        el.trigger("cooper:save:fail");
                    });
            },

            animate: function(el,klass)
            {
                el.removeClass(klass)
                    .addClass(klass)
                    .delay(1000)
                    .queue(function()
                    {
                        $(this).removeClass(klass).dequeue();
                    });
            },

            animateSuccess: function(el)
            {
                return this.animate(el,"animate_success");
            },

            animateFail: function(el)
            {
                return this.animate(el,"animate_fail");
            },

            undoSave: function(ev){
                var me = this;
                var el_undo = $(ev.target);

                var el = me.$el.find("[name="+el_undo.attr('data-undo')+"]" );

                if(me.collTrigger)
                {
                    me.model = me.collection.get(el.attr('data-id'));
                }

                var name  = el.attr('name');
                var value = el.attr('data-old-val');

                if(el.attr("multiple"))
                {
                    value = el.attr('data-old-val').split(',');
                }

                var toSave={};
                toSave[name]=value;

                if(value != el.val()){

                    el.val(value)
                        .trigger("change");
                    me.saveModel(el,toSave, name, ev);
                    me.$el.find("[data-undo='"+name+"']").addClass('hidden');
                }
            },

            toDataOldCheckbox: function(ev){
                var el = $(ev.target);
                $(el).attr('data-temp-val', el.prop('checked') );
                me.$el.find("[data-undo='"+name+"']"+" i").addClass('hidden');
            },

            changeInputCheckbox: function(ev){
                var el = $(ev.target);

                if(me.collTrigger)
                {
                    me.model = me.collection.get(el.attr('data-id'));
                }

                var name  = el.attr('name');
                var value = el.prop('checked');
                var toSave={};
                toSave[name]=value;

                if(value != el.attr('data-old-val')){

                    me.animateSuccess(el.parent());

                    this.model.save(toSave,{patch: true});
                    $(el).attr('data-old-val', !value );
                    me.$el.find("[data-undo='"+name+"']"+" i").removeClass('hidden');
                }
            },

            undoSaveCheckbox: function(ev){
                var el_undo = $(ev.target);
                var el = me.$el.find("name="+el_undo.parent().attr('data-undo')+"]" );

                if(me.collTrigger)
                {
                    me.model = me.collection.get(el.attr('data-id'));
                }

                var name  = el.attr('name');
                var value = (el.attr('data-old-val')=="true") ? true : false;
                var toSave={};
                toSave[name]=value;

                if(value != el.val()){
                    el.prop( 'checked' ,value);
                    this.model.save(toSave,{patch: true}).done(function(){
                        me.animateSuccess(el.parent());
                    });
                    me.$el.find("[data-undo='"+name+"']"+" i").addClass('hidden');
                }
            },

            deleteEntity: function(ev){
                var me = this;
       
                if (confirm("Вы уверены, что хотите удалить - "+this.model.id+"?"))
                {
                    if(me.model.urlRoot){
                        me.model.url = me.model.urlRoot;
                    }
                    me.model.destroy().done(function(model)
                    {
                        common.notify("Удачно удалено")
                        me.$el.trigger("entity:deleted", me.model)
                    })
                }
            },


        });
        return Backbone;
    });