<script>

import { eventBus } from "main"

export default {

  data () {
    return {
      currentComponent: null,
      state: {
        _rest: "",
        _tail: "",
        subpath: "",
        id: "",
      },
    }
  },
  
  created () {
    eventBus.on("routeChanged", ({ path, params }) => this.setState(path, params))
  },
  
  methods: {
    setState(path, params) {
    
      for (const el of (this.overlays || []))
      {
        if (path.match(el.re))
        {
          this.currentComponent = el.component
          break
        }
      }
    
      const state = {}

      const parts = path.split("/")
      state._rest = "/" + parts.slice(3).join("/")

      state.id      = parts[2]
      state.subpath = parts[3]
      state._tail   = parts[4]

      state.params = params
      for (const p of new URLSearchParams(params)) {
        state[ p[0] ] = p[1]
      }
      
      this.state = state
    },

  },
  
}

</script>
