<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniqueId class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>
    <div :class="'col-sm-'+size">
      <input class="form-control" autocomplete="off" :name=name :id=uniqueId :value=localValue :disabled=disabled
      v-maska:[maskaOpt] @change=newValue @focus=savePrevious />

      <Icon v-show="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
      style="cursor: pointer; pointer-events: auto;" @click.native=undo />

      <span class="help-block">{{error}}</span>
    </div>

    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>

  </div>
</template>

<script>
import CooperMixin from "common/visual/cooper/CooperMixin.vue"
import { vMaska } from "maska"

export default {
  name: "Ident",
  
  mixins: [CooperMixin],
  directives: { maska: vMaska },
  
  data() 
  {
    return {
      element: "input",
      maskaOpt: {
        mask: "wW",
        tokens: { 
          w: { pattern: /[_a-z]/i },
          W: { pattern: /[-_0-9a-z]*/i, repeated: true } 
        },
      },
    }
  },
  
  props: {
  },
  
  methods: {
  },

}
</script>
