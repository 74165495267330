<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0" >
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>

    <div :class="'col-sm-'+size">
      <input type="number" class="form-control" :name=name :id=uniq :value=localValue max=99999 min=0 step=1 
        @change=onInput />
      <span class="help-block">{{error}}</span>
    </div>

    <Icon v-if="info" name="fa-info-circle" :title=info />
    {{$store.state.userSettings.currency}}
    <slot></slot>
  </div>
</template>

<script>

import _ from "underscore"
import {fromMainCurrency, toMainCurrency} from "common"

export default {
  name: "Currency",
  
  data()
  {
    return { 
       uniq: null,
    }
  },
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    value: {
       type: String,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    error: {
      type: String,
    },
    info: {
      type: String,
    }
    
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("number")
  },
  
  methods: {
    decorateValue(sum)
    {
      return fromMainCurrency(sum)
    },
    undecorateValue(sum)
    {
      return toMainCurrency(sum)
    },
    
    onInput(event)
    {
      this.$emit("input", this.undecorateValue(event.target.value))
    }
  },
  
  computed: {
    localValue()
    {
      return this.decorateValue(this.value)
    },
  },
  
}
</script>
