define([
  "jquery",
  "jplayer",

  "_lib/jplayer/skin/blue.monday/css/jplayer.blue.monday-my.css",
], function($)
{
  return {
    create: function(el, options)
    {
      options = options || {};
      this.el = el;
 
      var promiseJplayer = $.Deferred();
      options.ready = () => promiseJplayer.resolve();
      options.cssSelectorAncestor = ".jp-audio";

      el.addClass("jp-jplayer");

      if (!options.layout) {
        el.after(this.layout);  
      } else {
        el.after(this[options.layout]);

        options.cssSelectorAncestor = '.jp-' + options.layout + '-audio';
      }
      
      el.jPlayer(options);
      return promiseJplayer.promise();
    },
    
    play: function(title, url, urlEntity)
    {
      if ( this.el.attr('data-entity-url') !== urlEntity ) {
        // не снятие с паузы, новый источник
        this.el.jPlayer("setMedia", {title: title, mp3: url});
        this.el.attr('data-entity-url', urlEntity);
      }

      this.el.jPlayer("play");
    },

    pause: function()
    {
      this.el.jPlayer("pause");
    },
    
    layout: `
<div class="jp-audio" role="application" aria-label="media player">
  <div class="jp-type-single">
    <div class="jp-gui jp-interface">
      <div class="jp-volume-controls">
        <button class="jp-mute" role="button" tabindex="0">mute</button>
        <button class="jp-volume-max" role="button" tabindex="0">max volume</button>
        <div class="jp-volume-bar">
          <div class="jp-volume-bar-value"></div>
        </div>
      </div>
      <div class="jp-controls-holder">
        <div class="jp-controls">
          <button class="jp-play" role="button" tabindex="0">play</button>
          <button class="jp-stop" role="button" tabindex="0">stop</button>
        </div>
        <div class="jp-progress">
          <div class="jp-seek-bar">
            <div class="jp-play-bar"></div>
          </div>
        </div>
        <div class="jp-current-time" role="timer" aria-label="time">&nbsp;</div>
        <div class="jp-duration" role="timer" aria-label="duration">&nbsp;</div>
        <div class="jp-toggles">
          <button class="jp-repeat" role="button" tabindex="0">repeat</button>
        </div>
      </div>
    </div>
    <div class="jp-details">
      <div class="jp-title" aria-label="title">&nbsp;</div>
    </div>
    <div class="jp-no-solution">
      <span>Update Required</span>
      To play the media you will need to either update your browser to a recent version or update your <a href="http://get.adobe.com/flashplayer/" target="_blank">Flash plugin</a>.
    </div>
  </div>
</div>`,

    layout_slim: `<div class="jp-audio pull-right" role="application" aria-label="media player">
  <div class="jp-type-single">
    <div class="jp-gui jp-interface slim-my">
      <div class="jp-volume-controls slim-my">
        <button class="jp-mute" role="button" tabindex="0">mute</button>
        <button class="jp-volume-max" role="button" tabindex="0">max volume</button>
        <div class="jp-volume-bar">
          <div class="jp-volume-bar-value"></div>
        </div>
      </div>
      <div class="jp-controls-holder">
        <div class="jp-controls slim-my">
          <button class="jp-play" role="button" tabindex="0">play</button>
          <button class="jp-stop" role="button" tabindex="0">stop</button>
        </div>
        <div class="jp-progress slim-my">
          <div class="jp-seek-bar">
            <div class="jp-play-bar"></div>
          </div>
        </div>
        <div class="jp-current-time slim-my" role="timer" aria-label="time">&nbsp;</div>
        <div class="jp-duration slim-my" role="timer" aria-label="duration">&nbsp;</div>
      </div>
    </div>
    <div class="jp-no-solution">
      <span>Update Required</span>
      To play the media you will need to either update your browser to a recent version or update your <a href="http://get.adobe.com/flashplayer/" target="_blank">Flash plugin</a>.
    </div>
  </div>
</div>
    `,
    layout_report: `
<div  class="jp-my-class jp-audio pull-right" role="application" aria-label="media player">
      <div class="jp-my-class jp-type-single">
        <div class="jp-my-class jp-gui jp-my-class jp-interface">
          <ul class="jp-my-class jp-controls">
            <li><a class="jp-my-class jp-play" tabindex="1" style="display: block;">play</a></li>
            <li><a class="jp-my-class jp-pause" tabindex="1" style="display: none;">pause</a></li>
            <li><a class="jp-my-class jp-stop" tabindex="1">stop</a></li>
            <li><a class="jp-my-class jp-mute" style="margin-left: 32px; margin-top: 6px;" tabindex="1" title="mute">mute</a></li>
            <li><a class="jp-my-class jp-unmute" tabindex="1" title="unmute" style="display: none; margin-left: 32px; margin-top: 6px;">unmute</a></li>
          </ul>
          <div class="jp-my-class jp-progress">
            <div class="jp-my-class jp-seek-bar" style="width: 100%;">
              <div class="jp-my-class jp-play-bar" style="width: 3.53579%;"></div>
            </div>
          </div>
          <div class="jp-my-class jp-volume-bar">
            <div class="jp-my-class jp-volume-bar-value" style="width: 80%;"></div>
          </div>
          <div class="jp-my-class jp-time-holder">
            <div class="jp-my-class jp-current-time">00:01</div>
            <div class="jp-my-class jp-duration">00:41</div>
          </div>
        </div>

        <div class="jp-my-class jp-no-solution" style="display: none;">
          <span>Update Required</span>
          To play the media you will need to either update your browser to a recent version or update your <a href="http://get.adobe.com/flashplayer/" target="_blank">Flash plugin</a>.
        </div>
      </div>
    </div>
    `,
    layout_r2: `
<div class="jplayer-test-bottom jp-layout_r2-my-class jp-layout_r2-audio ">
  <div class="jp-layout_r2-my-class jp-layout_r2-type-single">
      <div class="jp-layout_r2-my-class jp-layout_r2-gui  jp-layout_r2-interface">

        <!--<div class="jp-layout_r2-my-class jplayer-test-progressbar cursor-pointer"></div>-->


        <div class="jp-layout_r2-my-class jp-layout_r2-progress jplayer-test-progressbar">
        <div class="jp-layout_r2-my-class jp-layout_r2-current-time">00:01</div>
<div class="jp-layout_r2-my-class jp-layout_r2-duration">00:41</div>
            <div class="jp-layout_r2-my-class jp-layout_r2-seek-bar ">
                <div class="jp-layout_r2-my-class jp-layout_r2-play-bar "></div>
            </div>
        </div>
            
          <ul class="jp-layout_r2-my-class jp-layout_r2-controls " style="display: inline-block;">
            <li><a class="jp-layout_r2-my-class jp-layout_r2-play" tabindex="1" style="display: block;">
              <span class="glyphicon glyphicon-play" aria-hidden="true"></span>
            </a></li>
            <li><a class="jp-layout_r2-my-class jp-layout_r2-pause" tabindex="1" style="display: none;">
              <span class="glyphicon glyphicon-pause" aria-hidden="true"></span>
            </a></li>
            <li><a class=" jp-layout_r2-stop" tabindex="1">
                <span class="glyphicon glyphicon-stop" aria-hidden="true"></span>
            </a></li>
            <li><a class="jp-layout_r2-my-class jp-layout_r2-mute" style="margin-left: 32px; margin-top: 6px;" tabindex="1" title="mute">
              <span class="glyphicon glyphicon-volume-down" aria-hidden="true"></span>
            </a></li>
            <li><a class="jp-layout_r2-my-class jp-layout_r2-unmute" tabindex="1" title="unmute" style="display: none; margin-left: 32px; margin-top: 6px;">
              <span class="glyphicon glyphicon-volume-off" aria-hidden="true"></span>
            </a></li>
          </ul>

          <div class='jpm-ava pull-right'></div>

          <div class="jp-layout_r2-my-class jp-layout_r2-volume-bar">
            <div class="jp-layout_r2-my-class jp-layout_r2-volume-bar-value" style="width: 80%;"></div>
          </div>
      </div>
    </div>

</div>
    `

  }
});
