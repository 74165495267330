var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    [
      _vm.row.action == "add" && _vm.row.new instanceof Object
        ? _vm._l(_vm.keysOf(_vm.row.new), function(k) {
            return _c("tr", [
              _c("td", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.attrHumanName(k)))
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.row.new[k]))])
            ])
          })
        : _vm.row.action == "add"
        ? [_c("tr", [_c("td", [_vm._v(_vm._s(_vm.row.new))])])]
        : (_vm.row.action == "delete" || _vm.row.action == "disconnect") &&
          _vm.row.old instanceof Object
        ? _vm._l(_vm.keysOf(_vm.row.old), function(k) {
            return _c("tr", [
              _c("td", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.attrHumanName(k)))
              ]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.row.old[k]))])
            ])
          })
        : _vm.row.action == "delete" || _vm.row.action == "disconnect"
        ? [_c("tr", [_c("td", [_vm._v(_vm._s(_vm.row.old))])])]
        : _vm.row.action == "change" &&
          _vm.row.new instanceof Object &&
          _vm.row.old instanceof Object
        ? _vm._l(_vm.keysOf(_vm.row.old), function(k) {
            return _c(
              "tr",
              [
                _c("td", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.attrHumanName(k)))
                ]),
                _vm._v(" "),
                _vm.isStrLike(k)
                  ? _c(
                      "td",
                      [
                        _c("AttrDiff", {
                          attrs: {
                            long: _vm.isLong(k),
                            oldStr: _vm.row.old[k],
                            newStr: _vm.row.new[k]
                          }
                        })
                      ],
                      1
                    )
                  : [
                      _c("td", { staticClass: "from" }, [
                        _vm._v(_vm._s(_vm.row.old[k]))
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.row.new[k]))])
                    ]
              ],
              2
            )
          })
        : _vm.row.action == "change"
        ? [
            _c(
              "tr",
              [
                _vm.isStrLike(_vm.k)
                  ? _c(
                      "td",
                      [
                        _c("AttrDiff", {
                          attrs: {
                            long: _vm.isLong(_vm.k),
                            oldStr: _vm.row.old,
                            newStr: _vm.row.new
                          }
                        })
                      ],
                      1
                    )
                  : [
                      _c("td", { staticClass: "from" }, [
                        _vm._v(_vm._s(_vm.row.old))
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.row.new))])
                    ]
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }