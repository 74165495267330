<template>
<table class="table table-bordered">
  <tbody>
  <tr v-for="i in 5" :key=i >
    <td v-for="j in 5" :key=j >
      <div class="faux-cell" />
    </td>
  </tr>
  </tbody>
</table>
</template>

<script>
export default {
  name: "SkelTable",
}
</script>

<style scoped>
.faux-cell
{
  background: rgba(0,0,0,.12); 
  height: 2em;
  margin: 0.1em;
}
</style>