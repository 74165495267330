<template>
  <table>
    <template v-if="row.action=='add' && row.new instanceof Object">
      <tr v-for="k in keysOf(row.new)" >
        <td class="name">{{ attrHumanName(k) }}</td>
        <td>{{row.new[k]}}</td>
      </tr>
    </template>
    <template v-else-if="row.action=='add'">
      <tr>
        <td>{{row.new}}</td>
      </tr>
    </template>
    <template v-else-if="(row.action=='delete' || row.action=='disconnect') && row.old instanceof Object">
      <tr v-for="k in keysOf(row.old)" >
        <td class="name">{{ attrHumanName(k) }}</td>
        <td>{{row.old[k]}}</td>
      </tr>
    </template>
    <template v-else-if="row.action=='delete' || row.action=='disconnect'">
      <tr>
        <td>{{row.old}}</td>
      </tr>
    </template>
    <template v-else-if="row.action=='change' && row.new instanceof Object && row.old instanceof Object">
      <tr v-for="k in keysOf(row.old)" >
        <td class="name">{{ attrHumanName(k) }}</td>
        <td v-if="isStrLike(k)"><AttrDiff :long=isLong(k) :oldStr="row.old[k]" :newStr="row.new[k]" /></td>
        <template v-else>
          <td class="from">{{row.old[k]}}</td>
          <td>{{row.new[k]}}</td>
        </template>
      </tr>
    </template>
    <template v-else-if="row.action=='change'">
      <tr>
        <td v-if="isStrLike(k)"><AttrDiff :long=isLong(k) :oldStr="row.old" :newStr="row.new" /></td>
        <template v-else>
          <td class="from">{{row.old}}</td>
          <td>{{row.new}}</td>
        </template>
      </tr>
    </template>
  </table>
</template>

<script>
import _ from "underscore"
import AttrDiff from "common/visual/history/AttrDiff.vue"

export default {
  name: "HistoryRow",
  
  components: {AttrDiff},
  
  props: {
    row: Object,
  },
  
  data()
  {
    return {
      entity: "",
    }
  },
  
  created()
  {
    this.entity = this.$store.getters.matchEntity(this.row.system, this.row.entity)
  },
  
  methods: {
    attr(name)
    {
      if (!this.entity) return null

      try
      {
        return this.$store.getters.attr(this.row.system, this.entity, name)
      }
      catch
      {
        return null
      }
    },
    
    attrHumanName(name)
    {
      return this.attr(name)?.human || name
    },
    
    isStrLike(name)
    {
      return ["string", "phone"].find(el => el == this.attr(name)?.type)
    },
    
    isLong(name)
    {
      const len = this.attr(name)?.length
      return len && len>100
    },
    
    keysOf(rec)
    {
      return _.sortBy(Object.keys(rec), name => this.attrHumanName(name))
    },
  },
}
</script>

<style scoped>

table {
  border-collapse: collapse;
  width: 100%;
}

tbody tr:nth-child(even) {
  backdrop-filter: brightness(90%);
}

td.name {
  width: 10%;
  border-right: 1px solid lightgray;
  padding-left: 0;
  padding-right: 1em;
  font-weight: bold;
}

td {
  padding-left: 1em;
}

td.from {
  width: 45%;
  border-right: 1px solid lightgray;
}
</style>