var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm._l(_vm.items, function(item, i) {
        return _c("div", { key: i, staticClass: "panel" }, [
          _c("div", { staticClass: "panel-body" }, [
            _vm.withTitle
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("Input", {
                      attrs: {
                        "as-title": true,
                        size: 11,
                        name: "title",
                        apiDomain: _vm.apiDomain,
                        apiTopic: _vm.apiTopic,
                        apiPath: item.entity
                      },
                      model: {
                        value: _vm.items[i].title,
                        callback: function($$v) {
                          _vm.$set(_vm.items[i], "title", $$v)
                        },
                        expression: "items[i].title"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-sm",
                          on: {
                            click: function($event) {
                              return _vm.delItem(i)
                            }
                          }
                        },
                        [_vm._v("Удалить")]
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _vm._t("default", null, { parent: item }),
                  _vm._v(" "),
                  !_vm.withTitle
                    ? _c("Icon", {
                        attrs: { name: "trash" },
                        on: {
                          click: function($event) {
                            return _vm.delItem(i)
                          }
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-sm pull-right",
            staticStyle: { "margin-right": "3em" },
            on: { click: _vm.addItem }
          },
          [
            _c("Icon", { attrs: { name: "plus" } }),
            _vm._v(" " + _vm._s(_vm.addText))
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }