<template>
<div class="row">
  <div class="col-md-2" style="margin-bottom: 0px;">
    <SkelSearchInput v-for="i in 4" :key=i />
  </div>
                      
  <div class="col-md-10">
    <SkelTable />
  </div>
                                                                                     
</div>
</template>

<script>
import SkelTable from "common/visual/SkelTable.vue"
import SkelSearchInput from "common/visual/SkelSearchInput.vue"

export default {
  name: "SkelSearchTable",
  components: {SkelTable, SkelSearchInput},
}
</script>

<style>
</style>