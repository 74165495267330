var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SelectAdv", {
    attrs: {
      name: _vm.name,
      value: _vm.value,
      search: _vm.search,
      init: _vm.init
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }