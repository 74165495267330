define(['backbone', 'underscore', 'common/context/context'], function(Backbone, _, contextmenu){

	return function( param ){

		var me = this;
		if( _.isEmpty( param.view ) ) throw new Error('Не указана виев!');
	
		this.view = param.view;	

		/*Если нет событий создаем*/
		_.defaults(this.view, { events: {} });

		/*Выбор полей*/
		this.collection = new Backbone.Collection();

		this.collection.reset( param.rows );

		this.view.$('thead').css({ 'border-bottom': '2px solid #BBB', 'border-top': '2px solid #BBB' });

		/*Чтение состояния*/
		if( _.isObject( param.hash ) && _.isFunction( param.hash.get ) ){ 
			var array =  param.hash.get();
			if( array ){

				_.forEach( array, function(v){
					var model = me.collection.get(v.id);
					if( model ) model.set({ width: v.width, checked: 1 });
				});

				var data = _.pluck( array, 'id' );
				me.collection.comparator = function(m1,m2){
					var i1 = _.indexOf(data, m1.get('id')), i2 = _.indexOf(data, m2.get('id'));
					if( i1 > i2 ) return 1;
					if( i1 < i2 ) return -1;
					return 0;
				};
				me.collection.sort();
			}
		}

		this.collection.on('change', function(){
			var thead = [], tbody = [];

			_.forEach( _.where(this.collection.toJSON(), { checked: 1 }), function(v){

				//Размеры при инициалицаии
				var $th = $(v.thead);
				if( v.width ){
				    $th.outerWidth( v.width );
				} 

				$th.attr({ 'attr': v.id });
				thead.push($th.get(0).outerHTML);
				tbody.push(v.tbody);
			});

			//Отмена перетаскивание
//			this.view.$('thead td').draggable('disable').droppable('disable');

			//Отмена ресайза
//			this.view.$('thead td').resizable('disable');

			//Очищаем прошлые виевы
			_.each(this.view.oldView, function(v){
				v.remove();
			});
			this.view.oldView = {};

			//Подменяем заголовки и реднер
			this.view.$('thead tr').html( thead.join('') );
			this.view.modelView = this.view.modelView.extend({ template: tbody.join('') });

			//Перетаскивание
			this.view.$('thead td, thead th').draggable({
			  	opacity: 0.85,
				axis: 'x',
				zIndex: '1',
				revert: true,
			}).droppable({
				drop: function(event, ui){

					var t = $(event.target).attr('attr'), s = $(ui.draggable).attr('attr');
					if( !s || !t ) return;

					var data = me.collection.pluck('id');
					var tk = _.indexOf(data, t), sk = _.indexOf(data, s);

					data.splice( sk, 1 );
					data.splice( tk, 0, s );

					me.collection.comparator = function(m1,m2){
						var i1 = _.indexOf(data, m1.get('id')), i2 = _.indexOf(data, m2.get('id'));
						if( i1 > i2 ) return 1;
						if( i1 < i2 ) return -1;
						return 0;
					};

					me.collection.sort();
					me.collection.trigger('change');
					me.collection.trigger('sethash');

				}
			});

			//Ресайз
			var wsr, ws;
			this.view.$('thead td:not(:last-child), thead th:not(:last-child)').resizable({
				handles: "e",
				start: function( event, ui ){
					ws = parseInt(ui.element.outerWidth());
					wsr = parseInt(ui.element.next().outerWidth());
				},
				resize: function( event, ui){
					var w = parseInt(ui.element.outerWidth());

					if( w < 50 ) w = 50;
					if(( ws + wsr ) - w < 50) w = ws;

					var wr = ( ws + wsr ) - w;

					ui.size.width = w;
					ui.element.next().outerWidth( wr );

					wsr = wr;
					ws = w;
				},
				stop: function( event, ui ){

					me.view.$('thead td, thead th').each(function(k,v){
						var model = me.collection.get($(v).attr('attr'));
						if( model ){
						    model.set({ width: parseInt($(v).outerWidth())  }, { silent: true });
						}
					});

					me.collection.trigger('change');
					me.collection.trigger('sethash');
				}
		    });

		    //Перерендериваем
		    this.view.render();

		}, this);

		/*Сохранение состояния*/
		this.collection.on('sethash', function(){
	
			var array = [];
			_.forEach(_.where(this.collection.toJSON(), { checked: 1 }), function(v){
				array.push({ id: v.id, width: v.width });
			});
			if( _.isObject( param.hash ) && _.isFunction( param.hash.set ) ) param.hash.set( array );

		}, this);

		//Выбор столбцов (Контекстное меню)
	    this.view.$('thead').on('contextmenu', function(e){
			e.preventDefault();

			var array = [];
			 me.collection.forEach(function(model){
				array.push({
					id: model.get('name'),
					checked: model.get('checked'),
					name: '<label><input type="checkbox" '+((model.get('checked'))?'checked':'')+'>&nbsp;'+model.get('name')+'&nbsp;</label>',
					func: function(ec,e){
						model.set({ checked: +$(e.currentTarget).find('input').is(':checked') });
						me.collection.trigger('sethash');
					}
				});
			});

			array = _.sortBy(array, function(v){ return (v.checked) ? 1 : array.length; });
			contextmenu.show(e.originalEvent, array);
		});
		this.collection.trigger('change');
	};
});
