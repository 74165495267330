var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.at.type == "string" && _vm.at.len && _vm.at.len > 100
    ? _c("TextArea", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          maxlength: +_vm.at.len,
          rows: _vm.rows,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "string"
    ? _c("Input", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          maxlength: Number(_vm.at.len),
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "integer"
    ? _c("Number", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          min: +_vm.at.min,
          max: +_vm.at.max,
          step: +_vm.at.step,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "one-of"
    ? _c("Select", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          options: _vm.at.options,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "boolean"
    ? _c("Boolean", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "geo"
    ? _c("LocationInput", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "phone"
    ? _c("Phone", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "e-mail"
    ? _c("Input", {
        attrs: {
          type: "email",
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _vm.at.type == "money"
    ? _c("Currency", {
        attrs: {
          size: _vm.size,
          labelSize: _vm.labelSize,
          name: _vm.attr,
          label: _vm.label,
          info: _vm.at.info,
          value: _vm.value
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      })
    : _c("span", { staticStyle: { color: "red" } }, [
        _vm._v("GenInput: Unknown attribute type: " + _vm._s(_vm.at.type))
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }