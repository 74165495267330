<template>
<div class="row">

  <div class="col-md-2" style="margin-bottom: 0px;">
    <SearchPanel metaApiTopic="history" metaApiPath="/meta/history/search" :query=query @new-search=newSearch 
     :removedColumns=removedColumns
    />
  </div>
  
  <div class="col-md-10">
    <div style="overflow-y: auto;">
      <HistoryTable ref=table :name=name :title=title :toolbar=toolbar
       :showAdmin=showAdmin :showClient=showClient :showEntity=showEntity
       :syncURL=syncURL :state=state :apiPath=apiPath :paged=paged
       @load-query=loadQuery @toolbar-click=toolbarClick
      >
      </HistoryTable>
    </div>
  </div>
          
</div>
          
</template>

<script>

import SearchPanel from "common/search/SearchPanel.vue"
import HistoryTable from "common/visual/history/HistoryTable.vue"

export default {

  name: "HistorySearch",
  
  components: {SearchPanel, HistoryTable},
  
  props: {
      // Внутреннее имя таблицы, напр. для сохранения макета в настройках пользователя
      name: {
        type: String,
        required: true,
      },
      title: String,
      toolbar: {
        type: Array,
        default: () => []
      },
      // Синхронизировать URL с состоянием компонента (?page=1&query=xxx&sort=-a)
      syncURL: {
        type: Boolean,
        default: true,
      },
      apiPath: {
        type: String,
        required: true
      },
      // Начальное состояние таблицы
      state: {
        type: String,
        default: "",
      },
      // Включение необязательных полей
      showEntity: {
        type: Boolean,
        default: false,
      },
      showClient: {
        type: Boolean,
        default: false,
      },
      showAdmin: {
        type: Boolean,
        default: false,
      },
      // Постраничное чтение
      paged: Boolean,
  },
  
  created()
  {
    this.$on("reloadTable", () => this.$refs.table.$emit("reloadTable"))
  },
  
  data()
  {
    return {
      query: null,
    }
  },
  
  methods: {
    loadQuery(q)
    {
      this.query = q
    },
    
    newSearch(q)
    {
      this.$refs.table.$emit("setQuery", q)
    },
    
    toolbarClick(msg)
    {
      this.$emit("toolbar-click", msg)
    },
    
    currentQuery()
    {
      return this.$refs.table ? this.$refs.table.query : null
    }
  },
  
  computed: {
    removedColumns()
    {
      const c = []
      if (!this.showAdmin) c.push("admin")
      if (!this.showEntity) c.push("entity")
      if (!this.showClient) c.push("uid")
      return c
    },
  },
}
</script>
