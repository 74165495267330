define([
  'jquery',
  'underscore',
  'darsan',
  'common/visual/visual',
  'navigation',
  'common/search/grammar',

  'common/misc/datetime_picker',
  'device-search-input',
            
  'text-loader!common/search/layout.tpl',

], function($, _, darsan, visual, navigation, parser, datePicker, deviceSearchInput, searchTpl)
{
  return Object.create(visual).extend(
  {
    name: "search-panel",
  
    create: function(el, options)
    {
      var me = this;
      visual.create.apply(me,arguments);
      this.parent = options.parent;
      
      _.bindAll(me,"searchClick","clearAll");
      
      return darsan.get.apply(darsan,me.metaAddress).then(function(list)
      {
        var args = _.chain(list)
          .filter(function(el) { return el.type=="darsan-oneof"; })
          .map(function(el) { 
            return darsan.get("",el.topic,el.path).done(function(l)
            {
              el.values = _.map(l, function(el)
              {
                return {text: el.display_name, value: el.entity};
              });
              el.type = "oneof";
            });
          })
          .value();
        args.unshift(list);
        return $.when.apply($,args);
      })
      .done(function(list)
      {
        me.meta = list;
        me.renderFromTemplate(searchTpl, {me: me, list: list});

        me.$el.find("select:not(.compare-operator)").map((i, el) => {
          $(el).select2({ width: '100%' });
        });

        me.$el.find("#search").on("click",me.searchClick);
        me.$el.find("#clear").on("click",me.clearAll);
        me.$el.find("#save").on("click",me.saveClick);

        _.each(me.$el.find('.input-datepicker'), input =>
        {
          input = $(input);
          input.val("")
          
          var dateOptions = {
            name: input.attr("data-name"),
            id: input.attr("data-id"),
            time: input.hasClass("time-date"),
          };

          if (!input.hasClass("range-date")) { 
            input.append( datePicker.createDate(dateOptions) );
          }else{
            input.append( datePicker.createDateRange(dateOptions) );
          }
        });
        
        deviceSearchInput.create(null, null, me.$el.find(".device-search-input"));
        
      })
      .catch(err => console.log(err))
    },
    
    setState: function(state)
    {
      var me = this;
      
      if (!_.isNull(me.state) && state.list_query==me.state.list_query) return;
      if (_.isNull(me.state)) me.state = {};
      
      me.$el.find("input,select:not(.compare-operator)").each(function(i,el)
      {
        $(el).val(el.tagName=="SELECT" ? "\u200b" : "");
      });
      
      if (state.list_query)
      {
        state.list_query = decodeURIComponent(state.list_query)
        var params = me.queryValues(state.list_query);

        _.each(params, function(v)
        {
          var meta = me._meta(v.name);
          if (meta.type=="date" && v.op==">=") { 
            me.$el.find("[name='"+v.name+"']").data('daterangepicker').setStartDate(v.val);
          } else if (meta.type=="date" && v.op=="<=")  {
            me.$el.find("[name='"+v.name+"']").data('daterangepicker').setEndDate(v.val);
          } else if (meta.type == "compare") {
            me.$el.find("[name='"+v.name+"']").val(v.val);
            me.$el.find("[name='"+v.name+"-operator']").val(v.op);
          } else {
            // trigger("change") for select2
            me.$el.find("[name='"+v.name+"']").val(v.val).trigger("change");
          }
        });
        
        if (me.options.listChannel) {
          me.options.listChannel.trigger("subtitle:new", "Найдено");
        } else {
          me.callSibling("list", "setSearchName", "Найдено");
        }
      } else {
        // trigger("change") for select2
        me.$el.find("select:not(.compare-operator)").map((i, el) => {
          $(el).trigger("change");
        });
      }

      if (state.list_query) me.state.list_query = state.list_query;
    },
    
    searchClick: function(event)
    {
      var me = this;

      var newState = {list_page: 1, list_query: null};
      newState.list_query = me.makeQuery();

      navigation.changeState(newState);
    },
    
    makeQuery: function()
    {
      var me = this;
      var search = [];

      var inputs =  me.$el.find("input,select:not(.compare-operator)");

      inputs.each(function (i, el){
        var $el = $(el);
        var val = $el.val();

        var blank = el.tagName=="SELECT" ? "\u200b" : "";

        if (val != blank) {
          if ($el.hasClass("range-date")) {
            
            val = val.split(" - ");

            search.push(me._operation($el.attr("name"), val[0], {op: ">="} ));
            search.push(me._operation($el.attr("name"), val[1], {op: "<="} ));
          } else if ($el.hasClass("compare-value")) {
            var name = $el.attr("name");
            var operator = me.$el.find('select.compare-operator#' + name + '-operator').val();

            search.push(me._operation(name, val, { op: operator }));
          } else {
            search.push(me._operation($el.attr("name"),val));
          }
        }
      });

      return search.join(" AND ");
    },
    
    _operation: function(name,val,options)
    {
      var meta = this._meta(name);
      var options = options || {};
      var op = options.op || "=";

      switch(meta.type)
      {
        case "string":
          if (meta.wildcard && val.match(/\*/))
          {
            return name+' LIKE "'+val+'"';
          }
          else if (meta.implicit_wildcard && !val.match(/\*/))
          {
            return name+' LIKE "*' + val + '*"';
          }
          else
          {
            return name+'="'+val+'"';
          }
        break;
        
        case "oneof":
        case "radix-device":
          return name+'="'+val+'"';
        break;

        case "integer":
          return name+'='+val;
        break;
        
        case "boolean":
          return name+'='+ (val=="TRUE" ? 'TRUE' : 'FALSE');
        break;
        
        case "array":
          return name+'="'+val+'"';
        break; 
        
        case "date":
           return name+op+'"'+val+'"';
        break;

        case "compare":
          return name+op+'"'+val+'"';
      }
    },
    
    _meta: function(name)
    {
      return _.find(this.meta, {name: name});
    },
    
    clearAll: function()
    {
        navigation.changeState({list_query: null});

        if (this.options.listChannel) {
          this.options.listChannel.trigger("subtitle:new", "").trigger("change");
        }
    },
    
    parse: function(q)
    {
      var tree;
      
      try {
        tree = parser.parse(q);
      }
      catch(e)
      {
        return e;
      }
      
      return tree;
    },
    
    queryValues: function(q)
    {
      var tree = this.parse(q);
      if (tree instanceof parser.SyntaxError)
      {
        console.log("queryValues: ",tree);
        return [];
      }

      return this._extractValues(tree); 
    },
    
    _extractValues: function(tree)
    {
      var me = this;
      if (tree.list)
      {
        var list = [];
        tree.list.forEach(function(el)
        {
          list = list.concat(me._extractValues(el));
        });

        return list;
      }
      else
      {
        return [ {name: tree.l, val: tree.r, op: tree.op} ];
      }
    },
    
    stateVars: function()
    {
      return ["list_query", "list_page"];
    },
  
  }); // return
});                
