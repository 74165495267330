define([
  'jquery',
  'underscore',
  'backboneRadix',
  'common',
  'navigation',
  'permission',

  'common/table/table-view',
  'common/visual/visual',
  
  'text-loader!common/visual/list.tpl'

], function($, _, Backbone, common, navigation, perm, TableView, visual, listTemplate){
  "use strict";
  return Object.create(visual).extend({
    parent: null,
    name: "list",
    constData: {},

    create: function (el, opt) {
      var me = this;
      visual.create.apply(me, arguments);

      if (!me.tableTemplate) throw new Error("list: no tableTemplate");
      if (!me.rowTemplate) throw new Error("list: no rowTemplate");

      me.toolbarTemplate = _.template( me.toolbarTemplate || "");
      me.toolbarTemplate = me.toolbarTemplate( {perm: perm, common: common, me: me} );
      
      me.renderFromTemplate(listTemplate, {me: me});

      me.pager = new Backbone.PagerView({
        el: me.$(".pagination"),
        collection: me.collection
      });

      me.pager.on("setPage", function(page) {
        navigation.changeState({list_page: page});
        $('div.table-responsive').scrollTop(0);
      });

      _.bindAll(me, "setSearchName");

      if (me.options.listChannel) me.options.listChannel.on("subtitle:new", me.setSearchName);

      var table = new TableView({
        el: me.$el.find("div.table-responsive"),
        collection: me.collection,
        rowTemplate: me.rowTemplate,
        tableTemplate: me.tableTemplate,
        data: {perm: perm, module: me, common: common},
        rowClass: me.rowClass,
        extraRender: me.extraRender,
        extraRenderCollView: me.extraRenderCollView,
      });

      me.table = table;

      table.on("setSort", function(sort) { navigation.changeState({list_sort: sort}); });

      table.on("edit", function (model) {
        navigation.changeState({
          _rest: "/" + model.get('id'),
          list_page: null, list_search: null
        });
      });

      table.render();
    },
    
    setState: function (state) {
      var me = this;

      if ( !( state.list_search || state.list_query ) ) {
        me.setSearchName('');
      }
      
      var st = common.filterState(state, 'list');
      
      if ( me.state !== null && _.isEqual(me.state, st) ) {
        return;
      }
      me.state = st;
      
      var data = {};
      
      if (me.state.list_query) {
        data.query = decodeURIComponent(me.state.list_query)
      }
      
      if (me.state.list_search) {
        delete data.query;
        data.search = me.state.list_search;
      }

      if (me.state.list_sort) {
        data.sort = me.state.list_sort;
      }

      data.page = me.state.list_page || 1;
      _.extend(data, me.constData);
      me.collection.fetch( { reset: true, data: data } )
        .done( function() {
          var span = me.$el.find( ".search-name-count" );
          var length = me.collection.total;

          if ( +length !== 0 ) {
            span.removeClass( "label-warning" ).addClass( "label-success" );
          }

          span.html( length );
        } );
    },
    
    setSearchName: function( str, count )
    {
      if ( str ) {
        str = "(" + str + " <span class='label label-warning search-name-count'></span>" + ")";
      } else {
        str = "<span class='label label-warning search-name-count'></span>";
      }

      this.$el.find('#search-name').empty().append(str);
    }
  });
});