<template>
  <SelectAdv :name=name :search=search :adv-search=advSearch @input="$emit('input', $event)" @focus="$emit('focus', $event)" :options=options />
</template>

<script>
import SelectAdv from '_lib/select_adv/SelectAdv.vue'
import darsan from "darsan"
import _ from "underscore"
import $ from "jquery"

export default {
  name: "LocInput",

  components: {SelectAdv},

  props: {
    name: {
      type: String,
    },
    value: {
       type: String,
    },
  },
  
  data()
  {
    return {
      options: [],
    }
  },

  methods: {    
    init(opt) {
      const v = String(opt.id).split(/\|/)
      return { id: v[0], name: v[1] }
    },

    search(what) {
      return darsan.get('', 'geo', '/search', { what: what })
            .then(data => data.map(el => 
              {
                const full = el.fullname || '[' + el.entity + ']'
                return { id: el.entity + '|' + full, name: full }
              }))
    },

    advSearch(opt) {
      const v = String(opt.id).split(/\|/)

      return darsan
      .get('', 'geo', '/location/' + v[0] + '/children')
      .then(data  => data.map(el => {
        var full = el.fullname || '[' + el.entity + ']'
          return { id: el.entity + '|' + full, name: full }
      }))
    },
    
  },
  
  watch: {
    value: {
      immediate: true,
      handler(val)
      {
        const v = String(val).split(/\|/)
        this.options = [ { id: v[0], name: v[1], selected: 1 } ]
      },
    },
  },
    
}
</script>
