<template>
  <TextArea v-if="at.type=='string' && at.len && at.len>100" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :maxlength="+at.len" :rows=rows
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Input v-else-if="at.type=='string'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :maxlength=at.len
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Number v-else-if="at.type=='integer' || at.type=='float'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :min="+at.min" :max="+at.max" :step="+at.step"
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Select v-else-if="at.type=='one-of'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :options=at.options
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Boolean v-else-if="at.type=='boolean'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <LocationInput v-else-if="at.type=='geo'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Phone v-else-if="at.type=='phone'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Input v-else-if="at.type=='e-mail'" type=email :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Currency v-else-if="at.type=='money'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <Ident v-else-if="at.type=='ident'" :size=size :labelSize=labelSize :name=attr :label=label :info=at.info
    :apiDomain=apiDomain apiTopic=client :apiPath=apiPath :value=value @input="$emit('input', $event)" />
  <span style="color: red" v-else>GenInput: Unknown attribute type: {{at.type}}</span>
</template>

<script>

import Input from "common/visual/cooper/Input.vue"
import TextArea from "common/visual/cooper/TextArea.vue"
import Select from "common/visual/cooper/Select.vue"
import Number from "common/visual/cooper/Number.vue"
import Date from "common/visual/cooper/Date.vue"
import Currency from "common/visual/cooper/Currency.vue"
import LocationInput from "common/visual/cooper/LocationInput.vue"
import Phone from "common/visual/cooper/Phone.vue"
import Boolean from "common/visual/cooper/Boolean.vue"
import Ident from "common/visual/cooper/Ident.vue"

export default {

  name: "GenInput",
  
  components: {Input, TextArea, Select, Number, Date, Currency, LocationInput, Phone, Boolean, Ident},
  
  emits: ["input"],
  
  props: {
    entity: String,
    attr: String,
    attribute: Object,
    size: Number,
    labelSize: Number,
    value: {
       type: [String, window.Number, window.Boolean],
       default: "",
    },
    apiDomain: {
      type: String,
      default: "",
    },
    apiTopic: String,
    apiPath:  String,
    rows: Number,
  },
  
  data()
  {
    return {
    }
  },
  
  computed: {
    at()
    {
      return this.attribute || this.$store.getters.attr(this.apiTopic, this.entity, this.attr)
    },
    
    label()
    {
      return this.at.human || this.at.text
    },
  },
  
}
</script>