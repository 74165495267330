define([
  'underscore',
  'common',
  'navigation',
  'radio',
  'common/visual/visual',
  'text-loader!common/visual/extlist.tpl',
  'common/search/search',
],
function(_, common, nav, radio, Visual, template, Search) 
{
  return Object.create(Visual).extend({

    create: function(el,options)
    {
      var me = this;
      Visual.create.apply(me,arguments);

      me.name = me.name || "extlist";
            
      if (!me.listModule) throw new Error("extlist: no listModule");
      if (!me.listName) throw new Error("extlist: no listName");
      if (!me.searchMetaAddress) throw new Error("extlist: no searchMetaAddress");

      me.renderFromTemplate(template,{layout: me.layout, name: me.name, serial: me.serial});
                        
      me.searchModule = Object.create(Search).extend({
        metaAddress: me.searchMetaAddress,
      });

      _.extend(me.options, {
        listChannel: radio.channel("extlist-"+me.serial),
      });         

      var promises = [
        nav.promiseCreateModule(me.listModule, me.$(".list-container"), me.options),
        nav.promiseCreateModule(me.searchModule, me.$(".search-container"), me.options),
      ];

      return Promise.all(promises);
    },
    
    setState: function(state)
    {
      var me = this;

      if (_.isEqual(me.state,state)) return;
      me.state = state;

      // именно в таком порядке
      me.listModule.setState(state);
      me.searchModule.setState(state);
    },
                          
  });
});
