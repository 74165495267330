define([
  'vue/dist/vue.esm.js',
  'common/visual/visual',
  'navigation',
], function(Vue, Visual, nav)
{
  return Object.create(Visual).extend(
  {
    create(el, opt)
    {
      if (!this.vue) throw "vue-module: No Vue module "
      Visual.create.apply(this, arguments)
      const compClass = Vue.default.extend(this.vue.default)
      this.vueInstance = new compClass()
      this.vueInstance.$mount().$on("setState", function(st) { this.$data.state = st })
      this.$el.html(this.vueInstance.$el)
    },
  
    setState(state)
    {
      const vue = this.vueInstance
      this.vueInstance.$emit("setState", state)
    },
    
  })    
})
