define([
  'jquery', 'bootstrap-daterangepicker',
], function($)
{
	return {
		createDate: function(options){
			options = options || {};

			options.single = true;
		
			return this._createDate(options);
		},

		createDateRange: function(options){
			options = options || {};

			options.single = false;

			return this._createDate(options);
		},

		_createDate: function(options){
			var input = $("<input />", { class: "form-control input-datepicker", type: "text" });
			
			if(options.required) 	{ input.attr("required", "required"); }
			if(options.name) 			{ input.attr("name", options.name); }
			if(options.id) 				{ input.attr("id", options.id); }

			if(!options.single){input.addClass("range-date")}
			
			const dateFormat = options.time ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";

			var localePicker = {
                    applyLabel: 'Принять',
                    cancelLabel: 'Отмена',
                    fromLabel: 'От',
                    toLabel: 'До',
                    customRangeLabel: 'Выбрать',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт','Сб'],
                    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    firstDay: 1,
                    format: dateFormat,
                };

			var pickerFunc = function(start, end, label){
				var format = "YYYY-MM-DD";
				if(options.time){
					format = "YYYY-MM-DD HH:mm";
				}

				if(options.single){
					title = start.format(format);
				}else{
					title = start.format(format) + " - " + end.format(format);
				}				

				input.attr("title", title );
				
				if (options.autoUpdateInput)
				{
				  if (options.single) 
				  {
				    start.hours(0);
				    start.minutes(0);
				    start.seconds(0);
				  }
				  input.val(title)
			    }
			};			

			if(options.time)
			{
			  input.daterangepicker({ 
			    format: "YYYY-MM-DD HH:mm",
				singleDatePicker: options.single,
				showDropdowns: true,
				locale: localePicker,
				timePicker: true,
                timePicker12Hour: false,
			}, pickerFunc
         );	
		}
		else
		{
		  input.daterangepicker({ 
            format: 'YYYY-MM-DD',
            singleDatePicker: options.single,
            showDropdowns: true,
            locale: localePicker,
            autoUpdateInput: true,
          }, pickerFunc);
		}

		return input;
		}
	}

});
