var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group has-feedback",
      class: { "has-error": _vm.error },
      staticStyle: { "margin-bottom": "0" }
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label",
          class: "col-sm-" + _vm.labelSize,
          attrs: { for: _vm.uniq }
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: "col-sm-" + _vm.size },
        [
          _c("LocInput", {
            staticClass: "form-control",
            attrs: { name: _vm.name, id: _vm.uniq, value: _vm.value },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }