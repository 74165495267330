define([
  'jquery',
  'underscore',
  'common',
  'common/visual/visual',
  'navigation'
], function($, _, common, visual, nav)
{
  return {
    shown: function()
    {
      this.isVisible = true;
      this.trigger("page:shown");
    },
    
    hidden: function()
    {
      this.isVisible = false;
      this.trigger("page:hidden");
    },
    
    attach: function()
    {
      visual.attach.apply(this);
      this.shown();
    },

    detach: function()
    {
      visual.detach.apply(this);
      this.hidden();
    },
    
    setURL: function(url)
    {
      var state = common.URLToState(url);
      if (_.isFunction(this.parseState)) state = this.parseState(state);
      this.setState(state);
    },

    stateToURL: function(state)
    {
      return _.isFunction(this.pageStateToURL) ? this.pageStateToURL(state) : common.stateToURL(state);
    },
  };
});
