// https://www.fomfus.com/articles/how-to-use-ckeditor-4-with-webpack

import './loader.js'
import 'ckeditor/ckeditor'
import './config.js'

import _ from "underscore"

export default {
  create(selector, options)
  {
//    var e = window.CKEDITOR.replace( selector );
//    var e = window.CKEDITOR.replace( selector );




    var element = window.CKEDITOR.replace( selector );
    if (options && options.events) 
    {
//       _.each(options.events, (cb,key) => window.CKEDITOR.instances[selector].on(key,cb) );
       _.each(options.events, (cb,key) => element.on(key, cb));
    }
    return element;
  }
}
