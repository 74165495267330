var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "h4",
      {
        staticClass: "cursor-pointer collapsed",
        attrs: {
          "data-toggle": "collapse",
          "data-target": "#" + _vm.uniq,
          "aria-expanded": "false",
          "aria-controls": "collapsedAdditionalTags"
        }
      },
      [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _vm.shy
          ? [
              _c("span", { staticClass: "closed pull-right" }, [_vm._v("▾")]),
              _vm._v(" "),
              _c("span", { staticClass: "opened pull-right" }, [_vm._v("▴")])
            ]
          : [
              _c("Icon", {
                staticClass: "closed",
                attrs: { name: "chevron-down" }
              }),
              _vm._v(" "),
              _c("Icon", {
                staticClass: "opened",
                attrs: { name: "chevron-up" }
              })
            ]
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "collapse", attrs: { id: _vm.uniq } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }