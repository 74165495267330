/*Рендер коллекций запилен backfire*/
define([
  'jquery',
  'underscore',
  'backboneRadix',
  'permission',
], function($, _, Backbone){

	return Backbone.View.extend({
		setOption: function(param, value){
			var that = this;
		    if(param=='collection'){
				this.collection = value;

				this.listenTo(this.collection, 'add remove', function(){
					that.render();
				});
				this.listenTo(this.collection, 'reset', function(){
					that.render({ render: true });
				});
		    }
		},

		initialize: function( param ){
            if ( param.extraRenderCollView ) {
                this.extraRenderCollView = param.extraRenderCollView;
            }
			this.modelView = param.modelView;
			if( param.collection ) this.setOption('collection', param.collection );
		},
		render: function( param ){
		    param = param || {};
		    if( !this.oldView ) this.oldView = {};

		    var me = this;
		    //Подключаем виевы
		    var currView = {}, frag = document.createDocumentFragment();
		    this.collection.forEach(function(model){

				var view, fr = param.render;
				if( param.force || !this.oldView[model.cid] ){
					view = new this.modelView({ model: model });
					fr = true;
				}else{
					view = this.oldView[model.cid];
				}

				if ( fr ) view.render();

				currView[model.cid] = view;
				frag.appendChild(view.el)

		    }, this);

		    //Получаем el
		    var el = ( this.el.getElementsByTagName('tbody').length ) ? this.el.getElementsByTagName('tbody')[0] : this.el;

		    //Очищаем старое
		    var ol;
		    while (ol = el.lastChild) el.removeChild(ol);

		    //Вставляем новое
		    el.appendChild( frag );

		    //Уничтожаем неиспользуемые
		    _.each(this.oldView, function(v,k){ 
			if( !currView[k] ) v.remove(); 
		    }, this);

		    this.oldView = currView;

		    if (this.extraRenderCollView) {
			this.extraRenderCollView();
		    }

		    this.trigger('render');
		    return this;
		},
		events: {
		}
	});
});