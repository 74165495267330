var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-group" },
    [
      _vm._l(_vm.items, function(item, i) {
        return _c(
          "div",
          { key: item.entity, staticClass: "row" },
          [
            _c("GenInput", {
              attrs: {
                size: 9,
                "label-size": 2,
                attr: "value",
                attribute: _vm.findKind(item.type),
                apiDomain: _vm.apiDomain,
                apiTopic: _vm.apiTopic,
                apiPath: item.entity
              },
              model: {
                value: _vm.items[i].value,
                callback: function($$v) {
                  _vm.$set(_vm.items[i], "value", $$v)
                },
                expression: "items[i].value"
              }
            }),
            _vm._v(" "),
            _c("Icon", {
              attrs: { name: "trash" },
              on: {
                click: function($event) {
                  return _vm.delItem(i)
                }
              }
            })
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-12 pull-right",
            staticStyle: { "margin-right": "2em", "margin-top": "1em" }
          },
          [
            _c("div", { staticClass: "dropdown" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-sm dropdown-toggle",
                  attrs: {
                    type: "button",
                    id: _vm.uniqueId,
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "true"
                  }
                },
                [
                  _vm._v("\n           Добавить "),
                  _c("span", { staticClass: "caret" })
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": _vm.uniqueId }
                },
                _vm._l(_vm.kinds, function(k) {
                  return _c(
                    "li",
                    {
                      key: k.value,
                      staticClass: "arrow",
                      on: {
                        click: function($event) {
                          return _vm.addItem(k)
                        }
                      }
                    },
                    [
                      _vm._v(" "),
                      _c("Icon", { attrs: { name: k.icon } }),
                      _vm._v("  " + _vm._s(k.text))
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }