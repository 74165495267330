var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("StrDiff", {
    attrs: { oldStr: _vm.state.oldStr, newStr: _vm.state.newStr }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }