var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    _vm._l(_vm.diff, function(d) {
      return _c(
        "span",
        { class: d[0] == -1 ? "del" : d[0] == 1 ? "ins" : "" },
        [_vm._v(_vm._s(d[1]))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }