var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "h5",
      { on: { click: _vm.toggle } },
      [
        _vm._v(_vm._s(_vm.title) + "    "),
        _c("Icon", { attrs: { name: _vm.iconName } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.opened,
            expression: "opened"
          }
        ]
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }