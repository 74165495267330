define([
  'jquery',
  'underscore',
  'backboneRadix',
  'common/table/collection-view',
  'common',
  'moment',
  'permission',
], function($, _, Backbone, collectionView, common, moment, perm)
{
  return Backbone.View.extend({
  
    events: {
      "click th": "sortClick",
    },
  
    initialize: function(options)
    {
      var me = this;
      
      me.data = options.data || {};

      _.extend(this, _.pick(options, "rowTemplate", "tableTemplate", "data", "rowClass"));
      this.compiled = _.template(this.tableTemplate,null,{variable: "me"});

      if ( options.extraRender ) {
        this.extraRender = options.extraRender;
      }

      if ( options.extraRenderCollView ) {
        this.extraRenderCollView = options.extraRenderCollView;
      }

      var markup = this.compiled(this.data);
      this.$el.empty().append(markup);

      var modelViewType = options.modelViewType || 'TemplateView'

      var ModelView = Backbone[modelViewType].extend({
        template: this.rowTemplate,
        tagName: "tr",

        render: function()
        {
          me.data.model = this.model.toJSON();
          var markup = this.compiled({me: me.data, common: common, moment: moment, cooper: common.cooperMacro, perm: perm});
          this.$el.empty().append(markup);

          if (this.model.get('disabled') == 1 || this.model.get('disable') || this.model.get('error'))
          { // for disabled user
            this.$el.addClass("warning") ;
          }
          else
          {
            this.$el.removeClass("warning");
          }

          if (me.rowClass) {
            if (this.model.get(me.rowClass.modelAttr) == me.rowClass.attrValue) {
              this.$el.addClass(me.rowClass.class) ;
            }
          }
                                                                          
          return this;
        },

        initialize: function (args) {
          Backbone.TemplateView.prototype.initialize.apply(this, args);
          this.listenTo(this.model,"change", function(model) { this.render(); });
        }
      });                                                                                              
      
      this.collView = new collectionView( {
        el: this.$el.find("table"),
        modelView: ModelView,
        selectable : true,
        collection: this.collection,
        extraRenderCollView: this.extraRenderCollView
      });

      this.collection.on("reset",function() 
      {
        me.$el.find("th").removeClass("sorted-asc sorted-desc");

        if (me.collection.sortCrit) {
          var desc = false;
          var sort = me.collection.sortCrit;

          if (sort.substr(0,1) === "-") {
            sort = sort.substr(1);
            desc = true;
          }

          me.$el.find("th#col-"+sort).addClass(desc ? "sorted-asc" : "sorted-desc");
        } 
      });
    },
    render: function()
    {
      this.collView.render();

      if (this.extraRender) {
        this.extraRender();
      }
    },
    sortClick: function(event)
    {
      var el = $(event.target);
      if (event.target.tagName != "TH") el = el.parents("th");

      var id = el.attr("id").replace("col-","");
                  
      var sort;

      if (el.hasClass("sorted-desc")) {
        sort = "-"+id;
      } else {
        sort = id;
      }

      this.trigger("setSort",sort);                                                                      
    },
  });
});
