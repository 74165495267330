var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group has-feedback",
      class: { "has-error": _vm.error },
      staticStyle: { "margin-bottom": "0" }
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label",
          class: "col-sm-" + _vm.labelSize,
          attrs: { for: _vm.uniq }
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _c("div", { class: "col-sm-" + _vm.size }, [
        _c("input", {
          staticClass: "form-control",
          attrs: {
            list: "list" + _vm.uniq,
            name: _vm.name,
            id: _vm.uniq,
            maxlength: _vm.maxlength,
            type: _vm.type
          },
          domProps: { value: _vm.value },
          on: {
            change: function($event) {
              return _vm.$emit("input", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.datalist
          ? _c(
              "datalist",
              { attrs: { id: "list" + _vm.uniq } },
              _vm._l(_vm.datalist, function(option) {
                return _c("option", { domProps: { value: option.value } }, [
                  _vm._v(_vm._s(option.text))
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
      ]),
      _vm._v(" "),
      _vm.info
        ? _c("Icon", { attrs: { name: "fa-info-circle", title: _vm.info } })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }