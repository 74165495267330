<template>
<div>
  <h4 v-if="title"><span v-html="title"></span> <small style="font-size: 60%" class="label label-success">{{common.formatNumber(total)}}</small></h4>
  <div :class=divClass >
  <table class="table table-striped table-bordered table-hover table-my-sorted" style="margin-bottom: 0px;" >
    <thead>
    <tr>
      <th v-for="c in enabledColumns" 
       :name=c.name :style="{width: c.width+'%'}" 
       @contextmenu.prevent="openContextMenu"
      >
        <Icon :name=c.icon v-if="c.icon" /> {{c.title}}
        <span v-if="c.sort" class="pull-right">
          <Icon style="color: lightgray" class="to-right" v-show="c.sort && sortField!=c.name" name="sort" />
          <Icon style="color: gray" class="float-right" v-show="sortField==c.name && sortAsc" name="fa-arrow-down" />
          <Icon style="color: gray" class="float-right" v-show="sortField==c.name && !sortAsc" name="fa-arrow-up" />
        </span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="rec in rows" :class=rowClass(rec)>
        <td v-for="c in enabledColumns" :class=cellClass(c,rec) >
          <slot :name=c.name :rec=rec >{{ rec[c.name] }}</slot>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  
  <vue-context ref="menu" :closeOnClick=false :closeOnScroll=false :lazy=true v-if=dynamic >
  <li v-for="c in columnsDef" class="menu" >
    <label>
      <input type="checkbox" :checked=!c.hidden @click="toggleHidden(c.name) " />
      {{ c.title || c.name }}
    </label>
  </li>
  </vue-context>
</div>
</template>

<script>
  import {eventBus} from "main"

  import darsan from "darsan"
  import $ from "jquery"
  
  import {changeURLParam} from "navigation"
  import {notifyHttpError} from "common"
  
  import { VueContext } from 'vue-context'

  export default {
    name: 'SimpleTable',
    
    components: {VueContext},
    
    props: {
      // Внутреннее имя таблицы, напр. для сохранения макета в настройках пользователя
      name: {
        type: String,
        required: true,
      },

      title: String,

      columns: {
        type: Array,
        default: () => [],
      },
      // Добавляет редактирование макета: перенос колонок, изменение ширины, добавление и удаление
      dynamic: {
        type: Boolean,
        default: false,
      },
      // Начальное состояние таблицы (строка в виде ?page=1&query=xxx&sort=-a)
      state: {
        type: String,
        default: null,
      },

      // Функция получения данных (если не подходит стандартная)
      fetch: [Function, String],
      
      // Параметры доступа к данным (если используется функция получения по умолчанию)
      apiTopic: String,
      apiPath: String,

      // Раскраска строк
      rowClass:{
        type: Function,
        default: () => null,
      },
      // Раскраска ячеек
      cellClass:{
        type: Function,
        default: () => null,
      },
      
      // Класс блока таблицы 
      divClass: {
        type: String,
        default: "table-responsive",
      },
    },
    
    data() {
      return {
        rows: [],
        columnsDef: this.joinColumnsDefinitions(),
        total: 0,
        fetchFunction: this.defaultFetchRows,
      }
    },
    
    created() {
      // Требование перезагрузки таблицы
      this.$on("reloadTable", () => this.fetchRows())
      
      this.fetchFunction = this.fetch || this.defaultFetchRows   
      
      this.initFromState()
      
      this.$store.watch(
        (state, getters) => state.refreshTable[this.name],
        (val) => 
        {
          if (val==1) this.fetchRows()
        })
    },

    mounted() {
      if (this.dynamic) this.decorateTable()
    },
    
    computed: {
      enabledColumns() {
        return this.columnsDef.filter(el => !el.hidden)
      },
    },
    
    methods: {
      fetchRows() 
      {
        this.fetchFunction()
        .then(rec => {
          this.total = rec.length
          this.rows  = rec
          
          this.$store.commit("wasRefreshed", this.name)
        })
        .catch(res => { console.log(res); notifyHttpError(res) })
      },
      
      defaultFetchRows(args) {
        return darsan.get("", this.apiTopic, this.apiPath)
      },
      
      
      toggleHidden(name)
      {
        const col = this.columnsDef.find(el => el.name==name)
        if (!col) return
        this.$set(col, "hidden", !col.hidden)
        this.saveColumns()
      },
      
      openContextMenu(event) {
        if (this.dynamic) this.$refs.menu.open(event)
      },
      
      saveColumns()
      {
        darsan.putJSON("","darsan","/worker/"+
          (this.$store.state.user.pretend||this.$store.state.user.login)+
          "/config/radix/dynamic-table-"+this.name, this.columnsDef)
      },

      initFromState()
      {
        const params = new URLSearchParams(this.state)

        this.query = params.get("query")
        this.$emit("load-query", this.query)

        const p = params.get("page")
        const s = params.get("sort")
        
        this.currentPage = p ? +p : 1
        
        if (s)
        {
          const res = s.match(/^(-?)(.*)/)
          this.sortField=res[2]
          this.sortAsc = res[1] !== "-"
        }
      },
      
      loadRows(data)
      {
        this.rows = data
        this.total = data.length
      },
      
      decorateTable()
      {
        const me = this
        // Делаем заголовок таблицы перетаскиваемым и меняющим размер
        $(this.$el).find("th")
        .resizable({
          handles: "e",
          stop: function( event, ui )
          {
            const newW = ui.size.width
            const tableW = me.$el.getElementsByTagName("table")[0]
            const name = ui.element.attr("name")
            const col = me.columnsDef.find(el => el.name==name)
            me.$set(col, "width", (newW/tableW.offsetWidth*100).toFixed(2))
            me.saveColumns()
          }
        })
        .draggable({
          opacity: 0.85,
          axis: 'x',
          zIndex: '1',
          revert: true,
        })
        .droppable({
          drop(ev, ui)
          {
            const srcName = ui.draggable.attr("name")
            const dstName = ev.target.getAttribute("name")
            const srcIndex = me.columnsDef.findIndex(el => el.name==srcName)
            if (srcIndex==-1) return
            const deleted = me.columnsDef.splice(srcIndex, 1)
            const dstIndex = me.columnsDef.findIndex(el => el.name==dstName)
            me.columnsDef.splice(dstIndex, 0, deleted[0])
            me.saveColumns()
          } 
        })
      },

        // соединяет определения, взятые из сохраненного конфига с определениями, 
        // взятыми из определения колонок при вызове таблицы
        // из определения берутся только sort и title
        // нужно, чтобы последующие правки колонок в файлах *.vue учитывались, даже если есть сохраненный конфиг
        joinColumnsDefinitions()
        {
          const current = this.$store.state.userSettings["dynamic-table-"+this.name]
          const definitions = this.columns
          
          if (!(current && current.length)) return definitions
          
          for (const rec of current)
          {
            const def = definitions.find( el => el.name==rec.name )
            if (def)
            {
              rec.title = def.title
              if ("sort" in def) rec.sort = def.sort
            }
          }
          
          // переносим колонки, которых нет в current
          for (const rec of definitions)
          {
            const curr = current.find( el => el.name==rec.name )
            if (!curr)
            {
              current.push(rec);
            }
          }
          
          return current
        },

    },
    
    watch: {
      state: function(val) { this.fetchRows() },
    },

  }
  
</script>

<style scoped>
  table {
    max-width: 99.5%;
  }

  table.collection-list tr.selected {
    color: rgb(51, 51, 51);
    background-color: #d9edf7;
  }

  .menu {
    padding-left: 7px;
    padding-right: 7px;
  }

  @media screen  and (max-device-width: 480px) and (orientation: portrait) {
    .table-responsive {

    }

    .table-toolbar {
      min-height: 80px;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      margin-bottom: 5px;
      margin-left: 0px;
      align-items: flex-start;
    }
  }

  @media screen  and (min-device-width: 1024px) and (orientation: landscape)  {
    .table-responsive {
      height: calc(100vh - 160px);
    }
  }

@import '~vue-context/dist/css/vue-context.css'
</style>
