<template>
  <input type="hidden" :name=name :id=id :value=value />
</template>

<script>
const _typeToIcon = {
  switch: "sitemap",
  pon: "lightbulb-o",
  pontree: "tree",
  smart: "archive",
  radio: "wifi",
  any: "asterisk",
}

import darsan from "darsan"
import $ from "jquery"

export default {

  name: "RadixDeviceInput",

  props: {
    name: String,
    id: String,
    value: String,
  },
  
  mounted()
  {
    const me = this
    const options = {
      query: this._deviceAutocomplete,
      minimumInputLength: 3,
      dropdownCssClass: 'ui-dialog',
      language: "ru",

      formatNoMatches() { return "Устройство не найдено" },
      nextSearchTerm(found, term) { return found.text },

      formatResult(item) 
      {
        const attributes = item.disabled ? 'class="text-mute"' : ""
        return '<div ' + attributes + '><i class="fa fa-' + _typeToIcon[item.type] + '"></i>&nbsp;' + item.text + '</div>'
      },

      initSelection(el,callback) 
      { 
      	var val = $(el).val()
      	if (!val) return ''

        darsan.get('', 'device', val, {})
          .done(dev => callback({id: val, text: dev.name_ru||dev.name}));
       },
     }

      try 
      {
        $(this.$el).select2(options)
        $(this.$el).on("change", ev => this.$emit("input", ev.val))
      }
      catch(e) { this.common.notifyError(e) }
  },

  methods: {
    _deviceAutocomplete(query)
    {
      darsan.get("", "device", "/srv/search", { nameMask: query.term, type: ["switch","pon","pontree","smart","radio", "any"] })
        .done(function (data) {
          var results = data.map(el => 
          { 
            return { 
              id: el.device_entity, 
              text: el.name_ru||dev.name,
              type: el.device_entity.replace(/[^a-z]/g,""),
              disabled: el.disabled, 
            }
          });

          query.callback({ results: results, more: false });
        });
    },
    
  },

  watch: {
    value(val) { $(this.$el).select2("val", val) },
  },

}
</script>
