define([
  'jquery',
  'underscore',
  'common',
  'radio',
  'allowed',
  'common/visual/visual',
  'navigation',
], function($, _, common, radio, addMod, Visual, nav)
{

  return Object.create(Visual).extend({
  create: function(el,opt)
  {
    var me = this;
    Visual.create.apply(me,arguments);

    if (!me.name) throw new Error("block: no name");
    if (!me.layout) throw new Error("block: no layout");
    
    _.bindAll(me,"setToolbarButton","startSpin","stopSpin");
    
    me.layout = addMod.filterLayout(me.layout);
    me.renderFromTemplate(me.template,{me: me});

    me.$(".toolbar-button").on("click", (e) => 
    {
      var b = $(e.target).closest(".toolbar-button");
      var i = b.attr("data-module");
      var j = b.attr("data-number");
      var rec = me.layout[i];
      rec.toolbar[j].callback.call(me,rec.module,e); 
    });

    me.layout.map( (el,i) =>
    {    
      var channel = radio.channel("header-"+me.serial+"-"+i);
      channel.reply("setToolbarButton", me.setToolbarButton);
      channel.reply("startSpin",me.startSpin);
      channel.reply("stopSpin",me.stopSpin);
    });
      
    var promises = me.layout.map( (el,i) => 
      nav.moduleFromPath(el).then( () => 
        {
          el.module.headerChannel = "header-"+me.serial+"-"+i;
          return nav.promiseCreateModule(el.module,me.$("#"+me.name+"-"+i),opt);
        }
      )
    );
    return common.when(promises);
  },
  
  setState: function(state)
  {
    var me = this;
    if (_.isEqual(me.state,state)) return;
    
    me.state = state;
    _.chain(me.layout).pluck("module").invoke("setState",state);
  },
  
  generateToolbar: function(i,el)
  {
    //Добавляем кнопки
    el.toolbar = el.toolbar||[];

    //Скриншот
    el.toolbar.unshift({ hidden: true, style: 'inline', text: '', name: 'screenShot', icon: 'camera', callback: function(m,e){
	var $el  = $(e.target).closest('.panel');
	common.printScreen($el[0]);
    }});

    var s = '<div class="header-toolbar">\n';
    el.toolbar.forEach((t,j) => 
    {
      var inline = t.style && t.style=="inline";

      var icon = "";
      if (t.icon)
      {
        if (/^fa-/.test(t.icon))
        {
          icon = "fa "+t.icon;
        }
        else
        {
          icon = "glyphicon glyphicon-"+t.icon;
        }
      }
      
      if (inline)
      {
        s += `<span class="toolbar-button" ${ t.hidden ? 'data-on-secret-key="true" style="display:none;"' : ''} data-module="${i}" data-number="${j}" ${ t.title ? "title='"+t.title+"'" : ""} ${ t.name ? "name='"+t.name+"'" : ""}>`;
        s += `<i class="${icon} cursor-pointer"></i>`;
        s += `<span class="text">${t.text}</span>\n</span>\n`;
      }
      else
      {
        s += `<button type="button" ${ t.name ? "name='"+t.name+"'" : ""} ${ t.title ? "title='"+t.title+"'" : ""} ${ t.hidden ? 'data-on-secret-key="true" style="display:none;"' : ''} `;
        s += `class="btn btn-default btn-sm toolbar-button" data-module="${i}" data-number="${j}">\n`;
        s += `<i class="${icon} cursor-pointer"></i>\n`;
        if (t.text) s += `&thinsp;<span class="text">${t.text}</span>\n`;
        s += `</button>\n`;
      }
    });

    s += "</div>\n";
    return s;  
  },
  
  setToolbarButton: function(name,attr)
  {
    var me = this;
    var button = me.$("div.header-toolbar button.toolbar-button[name="+name+"]");
        
    if (attr.text)
    {
      var span = button.find("span.text");
      span.html(attr.text);
    }
        
    if (attr.icon)
    {
      var span = button.find("span.glyphicon");
      span.removeClass().addClass("glyphicon").addClass("glyphicon-"+attr.icon);
    }
  },
  
  startSpin: function(name)
  {
    var me = this;
    var button = me.$("div.header-toolbar span.toolbar-button[name="+name+"] .glyphicon");
    button.addClass("fa-spin");
  },
  
  stopSpin: function(name)
  {
    var me = this;
    var button = me.$("div.header-toolbar span.toolbar-button[name="+name+"] .glyphicon");
    setTimeout( () => button.removeClass( "fa-spin" ), 300);
  },
  
  });
});
