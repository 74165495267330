<template>
  <StrDiff :oldStr=state.oldStr :newStr=state.newStr />
</template>

<script>
import StrDiff from "common/visual/history/StrDiff.vue"

export default {
  name: "ModalDiff",

  components: {StrDiff},
  
  data()
  {
    return {
      state: {oldStr: "", newStr: ""},
    }
  },

}
</script>
