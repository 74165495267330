<template>
  <span>
    <span :class="d[0]==-1 ? 'del' : d[0]==1 ? 'ins' : ''" v-for="d in diff" >{{d[1]}}</span>
  </span>
</template>

<script>
import diff_match_patch from "diff-match-patch"

export default {
  
  name: "StrDiff",
  
  props:
  {
    oldStr: String,
    newStr: String,
  },
  
  data()
  {
    return {
    }
  },
  
  computed: {
  
    diff()
    {
      const dmp = new diff_match_patch()
      const d = dmp.diff_main(this.oldStr, this.newStr)
      dmp.diff_cleanupSemantic(d)
      return d
      // Result: [(-1, "Hello"), (1, "Goodbye"), (0, " World.")]
    },
  },
  
}
</script>

<style scoped>
.del {
  background: #f5bfbf;
  text-decoration: line-through;
}

.ins {
  background: #a2e9b4;
}
</style>
