<template>
    <input type="text" class="form-control input-datepicker" :name=name :id=id :value=value 
     :class="{'range-date': range}" />
</template>

<script>

const localePicker = {
  applyLabel: 'Принять',
  cancelLabel: 'Отмена',
  fromLabel: 'От',
  toLabel: 'До',
  customRangeLabel: 'Выбрать',
  daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт','Сб'],
  monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
  firstDay: 1
}

import * as datepicker from "bootstrap-daterangepicker"

export default {
  name: "DatePicker",
  
  props: {
    range: {
      type: Boolean,
      default: false,
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    name: String,
    id: String,
    value: String,
  },
  
  data()
  {
    return {
    }
  },
  
  mounted()
  {
    const locale = {...localePicker}
    locale.format = this.dateFormat

    if (this.withTime)
    {
      $(this.$el).daterangepicker({ 
        autoUpdateInput: false,
        singleDatePicker: !this.range,
        showDropdowns: true,
        locale: locale,
        timePicker: true,
        timePicker24Hour: true,
      }, this.selected)	
    }
    else
    {
      $(this.$el).daterangepicker({ 
        singleDatePicker: !this.range,
        autoUpdateInput: false,
        showDropdowns: true,
        locale: locale,
      }, this.selected)
    }
  },
  
  methods: {
    selected(start, end, label)
    {
      if (this.range)
      {
        this.$emit("input", start.format(this.dateFormat) + " - " + end.format(this.dateFormat))
      }
      else
      {
        this.$emit("input", start.format(this.dateFormat))
      }
    },
  },
  
  computed: {
    dateFormat()
    {
      return this.withTime ? "YYYY-MM-DD HH:mmZZ" : "YYYY-MM-DD";
    }
  },
}
</script>
