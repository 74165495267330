<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniqueId class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>
    <div :class="'col-sm-'+size">
      <textarea class="form-control" :name=name :id=uniqueId :disabled=disabled
      :rows=rows @change=newValue @focus=savePrevious 
      >{{localValue}}</textarea>

      <Icon v-if="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
      style="cursor: pointer; pointer-events: auto;" @click.native=undo />

      <span class="help-block">{{error}}</span>
    </div>
    
    <Icon v-if="info" name="fa-info-circle" :title=info />
  </div>
</template>

<script>

import CooperMixin from "common/visual/cooper/CooperMixin.vue"

export default {
  name: "TextArea",
  
  mixins: [CooperMixin],
  
  data() 
  {
    return {
      element: "textarea",
    }
  },
  
  props: {
    rows: {
      type: Number,
      default: 2,
    },
  },

}
</script>

