var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-2", staticStyle: { "margin-bottom": "0px" } },
      [
        _c("SearchPanel", {
          attrs: {
            metaApiTopic: "history",
            metaApiPath: "/meta/history/search",
            query: _vm.query,
            removedColumns: _vm.removedColumns
          },
          on: { "new-search": _vm.newSearch }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-10" }, [
      _c(
        "div",
        { staticStyle: { "overflow-y": "auto" } },
        [
          _c("HistoryTable", {
            ref: "table",
            attrs: {
              name: _vm.name,
              title: _vm.title,
              toolbar: _vm.toolbar,
              showAdmin: _vm.showAdmin,
              showClient: _vm.showClient,
              showEntity: _vm.showEntity,
              syncURL: _vm.syncURL,
              state: _vm.state,
              apiPath: _vm.apiPath,
              paged: _vm.paged
            },
            on: {
              "load-query": _vm.loadQuery,
              "toolbar-click": _vm.toolbarClick
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }