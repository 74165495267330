define([
  'jquery',
  'underscore',
  'common',
  'common/visual/block',
  'text-loader!common/visual/horizontal.tpl'
], function($, _, common, Block, template)
{
  return Object.create(Block).extend({
    template: template,
  });            
});
