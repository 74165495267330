var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    class: _vm.iconClass,
    attrs: { title: _vm.popup },
    on: { click: _vm.onClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }