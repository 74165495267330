define(["jquery","darsan"], function($, darsan) {
  return {
  
    create: function(container, name, input, options) 
    {
      var me = this;
      
      options = options || {};
      options.query = me._deviceAutocomplete;
      options.minimumInputLength = 3;

      options.formatInputTooShort = function (term, len) { return "Введите " + len + " символ(а)"};
      options.formatNoMatches = function() { return "Устройство не найдено" };
      options.formatSearching = function() { return "Поиск..." };
      options.nextSearchTerm = function (found, term) { return found.text };

      options.formatResult = function (item) 
      {
        const attributes = item.disabled ? 'class="text-mute"' : "";
        return '<div ' + attributes + '><i class="fa fa-'+me._typeToIcon[item.type]+'"></i>&nbsp;' + item.text + '</div>';
      };

      options.initSelection = function(el,callback) 
      { 
      	var val = $(el).val();
      	if (!val) return '';

//	if(val == 'any') return callback({ id: "/any/1", text: "Любое", type: 'any' });

        darsan.get('', 'device', val, {})
          .done(dev => callback({id: val, text: dev.name_ru||dev.name}));
       };

      options.dropdownCssClass = 'ui-dialog';

      if (!input) 
      {
          input = $("<input/>", { type: 'hidden', name: name, value: val });  
          $(container).append(input);
      }

try {
      $(input).select2(options);
}
catch(e) { console.log(e) }
      $(input).on("change", function() { $(this).trigger("cooper:changed") });
      
      return input;
    },

    _deviceAutocomplete: function(query)
    {

//        if(query.term == 'any') return query.callback({ results: [{ id: "/any/1", text: "Любое", type: 'any' }], more: false });
      darsan.get("", "device", "/srv/search", { nameMask: query.term, type: ["switch","pon","pontree","smart", "any"] })
        .done(function (data) {
          var results = $.map(data, el => 
          { 
            return { 
              id: el.device_entity, 
              text: el.name_ru||dev.name,
              type: el.device_entity.replace(/[^a-z]/g,""),
              disabled: el.disabled, 
            }
          });

          query.callback({ results: results, more: false });
        });
    },
    
    _typeToIcon:
    {
      switch: "sitemap",
      pon: "lightbulb-o",
      pontree: "tree",
      smart: "archive",
    },
    
  };
});
