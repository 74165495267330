define([
  'common',
  'jquery',
  'underscore',
  'common/visual/oneof',
  'navigation',
  'text-loader!common/visual/accordion.tpl',
], function(common,$, _, OneOf, nav, template)
{
  return Object.create(OneOf).extend({
  
    template: template,

    create: function(el,opt)
    {
      var me = this;
      OneOf.create.apply(me,arguments);
      
      _.bindAll(me,"tabShown","tabHidden");

      this.layout.forEach((el,i) =>
      {
        var id = "accordion-"+this.serial+"-"+i;
        var a = me.$("div#"+id);
        a.on("show.bs.collapse", ev => me.tabShown(i) );
        a.on("hide.bs.collapse", ev => me.tabHidden(i) );
      });
    },
    
    ///////////////////////////////////////////////////////
    
    setState: function(state){
      var me = this;
      if (_.isEqual(me.state,state)) return;
      me.state = state;
      
      //console.log("rendering acc panel",me.name,me.state);
      var needs = me.state[me.name] || "";
      var i = me.findModuleIndex(needs) || 0;
      me.state[me.name] = needs;
      //console.log("acc panel needed", me.name+"=",needs);
      me._tabModuleSetState(i, true);
    },

    tabShown: function(tab)
    {
      var me = this;
      var f = me.layout[tab];
      
      var actives = me.$("#accordion-"+me.serial).find('.in, .collapsing');
      actives.each( function (index, element) 
      {
        $(element).collapse('hide');
      });
      
      nav.moduleFromPath(f).done( () =>
      {
        var mod = f.module;
        nav.changeURL({ [me.name]: mod.name });
        this._tabModuleSetState(tab,false);
      });
    },
    
    tabHidden: function(tab)
    {
      var me = this;
      var f = me.layout[tab]
      var mod = f.module;
      
      var vars = _.isFunction(mod.stateVars) ? mod.stateVars() : [];
      var diff = {};
      vars.forEach(function(el)
      {
        diff[el] = null;
      });
      nav.changeURL(diff);
    },
    
    _tabModuleSetState(tab,show)
    {
      var me = this;
      var f = me.layout[tab];
      if (!f)
      {
        throw new Error("_accModuleSetState: no module '"+tab+"' in accpanel '"+me.name+"'");
      }
      
      nav.moduleFromPath(f).done( () =>
      {
        var mod = f.module;
      
        me.state[me.name] = mod.name;

        var cont = me.$("#"+me.name+"-"+tab);
        if (!cont.length) throw new Error("accordion: No container found: #"+me.name+"-"+tab);
        return nav.promiseCreateModule(mod, me.$("#"+me.name+"-"+tab), me.modParams)
          .done(function()
          {
              mod.setState(me.state);
              me.currentModIndex = tab;
              if (show)
              {
                me.$("div#accordion-"+me.serial+"-"+tab).collapse('show');
              }
          });
        });
    },
    
  }); // object
});
