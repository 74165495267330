//Список провайдеров карт
config.mapProviders = [
    { id: 'osm', name: 'OSM' },
    { id: 'visicom', name: 'Visicom' },
    { id: 'google', name: 'Google' },
    { id: 'bing', name: 'Bing' },
    { id: 'yandex', name: 'Yandex' },
    { id: '2gis', name: '2 GIS' },
];

define(['jquery', 'underscore', 'leaflet', '_lib/leaflet/leaflet.css'], function($, _){

function script(url) {
  if (Array.isArray(url)) {
    let self = this;
    let prom = [];
    url.forEach(function (item) {
      prom.push(script(item));
    });
    return Promise.all(prom);
  }

  return new Promise(function (resolve, reject) {
    let r = false;
    let t = document.getElementsByTagName('script')[0];
    let s = document.createElement('script');

    s.type = 'text/javascript';
    s.src = url;
    s.async = true;
    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(this);
      }
    };
    s.onerror = s.onabort = reject;
    t.parentNode.insertBefore(s, t);
  });
}

	delete L.Icon.Default.prototype._getIconUrl;

	L.Icon.Default.mergeOptions({
	  iconRetinaUrl: require('_lib/leaflet/images/marker-icon-2x.png').default,
	  iconUrl: require('_lib/leaflet/images/marker-icon.png').default,
	  shadowUrl: require('_lib/leaflet/images/marker-shadow.png').default
	});

    return function( options ){

	options = options || { center: [0,0], zoom: 10 };
        var $el = $('<div>', { id: 'geo' }).css({ width: 'inherit', height: 'inherit' });
        var map = L.map( $el.get(0), options);

	var controlLayer = new L.Control.Layers();
	map.addControl( controlLayer );

        var map_p = userSettings.mapProviders, map_d = 'osm';
	var d = [];

	if( _.contains(map_p, 'osm')){
	    Promise.all(d).then(function(){

              let osm = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
              controlLayer.addBaseLayer(osm, 'OSM');

              if( map_d == 'osm' ) map.addLayer(osm);
	    });
	}

	if( _.contains(map_p, 'google')){

	    d.push(
		import('_lib/leaflet/plugins/GoogleMutant'),
		script('https://maps.google.com/maps/api/js?v=3&key=AIzaSyCi_YlI6ewDAzbfBh6SjEY6gIKu--VHUH8123123123123')
	    );

	    Promise.all(d).then(function(){
//              let g1 = L.gridLayer.googleMutant({ type: 'roadmap' }), g2 = L.gridLayer.googleMutant({ type: 'satellite' });

	      let g1 = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {subdomains:['mt0','mt1','mt2','mt3']}), g2 = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {subdomains:['mt0','mt1','mt2','mt3']})
	      controlLayer.addBaseLayer(g1, 'Google');
	      controlLayer.addBaseLayer(g2, 'Google (Спутник)');

              if( map_d == 'google' ) map.addLayer(g1);
	    });
	}

	if( _.contains(map_p, 'yandex')){

	    d.push(
		import('_lib/leaflet/plugins/tile/Yandex'),
		script('https://api-maps.yandex.ru/2.1/?load=package.map&lang=ru-RU')
	    );

	    Promise.all(d).then(function(){
              let y1 = new L.Yandex('map'), y2 = new L.Yandex('satellite')
              controlLayer.addBaseLayer(y1, 'Yandex');
              controlLayer.addBaseLayer(y2, 'Yandex (Спутник)');

              if( map_d == 'yandex' ) map.addLayer(y1);
	    });
	}

	if( _.contains(map_p, 'bing')){

	    d.push(
		import('_lib/leaflet/plugins/tile/Bing'),
	    );

	    Promise.all(d).then(function(){
		let b1 = new L.BingLayer("Aucw4V86233321348u7lIQz60j7eGgTKXHNiH9z3nhx_PxhaRvpX7uC5Eln68dLkgXBryvrm", { type: "Road" }), b2 = new L.BingLayer("Aucw4V868u7lIQz60j7eGgTKXHNiH9z3nhx_Pxh12341234aRvpX7uC5Eln68dLkgXBryvrm", { type: "Aerial" });
		controlLayer.addBaseLayer(b1, 'Bing');
		controlLayer.addBaseLayer(b2, 'Bing (Спутник)');

              if( map_d == 'bing' ) map.addLayer(b1);
	    });
	}

	if( _.contains(map_p, 'visicom')){
	    Promise.all(d).then(function(){
               let vc = new L.TileLayer('https://tms{s}.visicom.ua/2.0.0/planet3/base_ru/{z}/{x}/{y}.png',{ maxZoom : 19, tms : true, subdomains : '123'});
              controlLayer.addBaseLayer(vc, 'Visicom');

              if( map_d == 'visicom' ) map.addLayer(vc);
	    });
	}

	if( _.contains(map_p, '2gis')){
	    Promise.all(d).then(function(){
	      let dg = new L.tileLayer('http://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}');
              controlLayer.addBaseLayer(dg, '2 GIS');

              if( map_d == '2gis' ) map.addLayer(vc);
	    });
	}

          if( !map_d ) Promise.all(d).then(function(){ map.addLayer(controlLayer._layers[0].layer) });
        return { el: $el, map: map };
    }
});
