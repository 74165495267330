var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group has-feedback",
      class: { "has-error": _vm.error },
      staticStyle: { "margin-bottom": "0px" }
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label",
          class: "col-sm-" + _vm.labelSize,
          attrs: { for: _vm.uniqueId }
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: "col-sm-" + _vm.size },
        [
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "number",
              name: _vm.name,
              id: _vm.uniqueId,
              min: _vm.min,
              max: _vm.max,
              step: "1"
            },
            domProps: { value: _vm.localValue },
            on: {
              change: _vm.newValue,
              blur: _vm.newValue,
              focus: _vm.savePrevious
            }
          }),
          _vm._v(" "),
          _vm.has_undo
            ? _c("Icon", {
                staticClass: "form-control-feedback opacity-half-full",
                staticStyle: { cursor: "pointer", "pointer-events": "auto" },
                attrs: { name: "fa-reply" },
                nativeOn: {
                  click: function($event) {
                    return _vm.undo.apply(null, arguments)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
        ],
        1
      ),
      _vm._v(" "),
      _vm.info
        ? _c("Icon", { attrs: { name: "fa-info-circle", title: _vm.info } })
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.$store.state.userSettings.currency) + "\n  "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }