<template>
<div class="row">
  <div class="col-md-2">
    <div class="faux-label" />
  </div>
  <div class="col-md-10">
    <div class="faux-input" />
  </div>
</div>
</template>

<script>
export default {
  name: "SkelInput",
}
</script>

<style scoped>
.faux-label
{
  background: rgba(0,0,0,.12); 
  width: 100%; 
  height: 1em;
  margin-bottom: 0.5em; 
}

.faux-input
{
  background: rgba(0,0,0,.12); 
  width: 95%; 
  height: 2.1em;
  margin-bottom: 1em;
}
</style>