define([
  'jquery',
  'underscore',
  'navigation',
  'text-loader!common/popover/popover.tpl'], function($, _, navigation, html){
  
  'use strict';

  var $el = $('<div>', { html: html }), $elp = $el.find('.popover');
  $('html').append($el);
  
  var cache = {}, le, lparam;
  $el.find('.popover-content').bind("DOMSubtreeModified",function(){
	$elp.css(popover.setPosition( le, lparam ));
  });

  var cache = {}, le, lparam;
  var popover = {
		setPosition: function( e, param ){

			//Расчет полложения элемента
			var $ct = $(e.currentTarget);
//			var ep = { width: $ct.width(), height: $ct.height() };

			var ep = { width: $ct.outerWidth(), height: $ct.outerHeight() };
			ep = _.extend( $ct.offset(), ep );

//			var elp = { width: $elp.width(), height: $elp.height() };
			var elp = { width: $elp.outerWidth(), height: $elp.outerHeight() };
			var epos = { left: e.pageX, top: e.pageY }, offset = 0;

			$elp.addClass( param.placement );
			if( param.placement == 'left' ){
				epos.left = ep.left - elp.width - offset;
				epos.top =  ( ep.top - ( elp.height / 2 ) + ep.height / 2 ) - offset / 2;
			}
			if( param.placement == 'top' ){
				epos.left = ( ep.left - ( elp.width / 2 ) + ep.width / 2 ) - offset / 2;
				epos.top =  ep.top - elp.height - offset;
			}
			if( param.placement == 'bottom' ){
				epos.left = ( ep.left - ( elp.width / 2 ) + ep.width / 2 ) - offset / 2;
				epos.top = ep.top + ep.height + offset;
			}
			if( param.placement == 'right' ){
				epos.left = ep.left + ep.width + offset;
				epos.top =  ( ep.top - ( elp.height / 2 ) + ep.height / 2 ) - offset / 2;
			}
			return epos;

		},
		show: function(e, param){
			var me = this;

			param = _.extend({ placement: 'top' }, param );

			le = e;
			lparam = param;

			var $title = $el.find('.popover-title').hide();
			if( param.title ) $title.html( param.title ).show();

			$el.find('.popover-content').html( param.content );

			$elp.removeClass('left right top bottom').addClass( param.placement )
			$elp.show().css( me.setPosition( e, param ) ).css({ zIndex: 10000 });

/*
			$(e.currentTarget).one('click mouseout', function(){
				me.close();	
			});
*/
		},
		showModule: function(e, param, mod, state){
		   param = param||{};

		   var me = this;
			if( cache[mod.name] ){

				mod = cache[mod.name];
				param.content = mod.$el;
				mod.setState( state );
				me.show( e, param );
	
			}else{

		   		navigation.promiseCreateModule(mod, $('<div>')).then(function(){
					param.content = mod.$el;
					mod.setState( state );
					me.show( e, param );
 		   		});
 		   	}
		},
		close: function(){
			_.map(cache, function(mod){ if (!mod.detached) mod.detach(); });

			$el.find('.popover').hide();
			$el.find('.popover-content').children().detach();
			$el.find('.popover-title').hide();
		}
	}

	return popover;
});

