<template>
  <Select :options=options :name=name :label=label :size=size :labelSize=labelSize :info=info :disabled=disabled
          :value=value  @input=input />
</template>

<script>
import _ from "underscore"
import Select from "common/visual/input/Select.vue"

export default {
  name: "Boolean",
  
  components: {Select},
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    size: {
      type: Number,
      default: 6,
    },
    value: {
      type: String,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
     error: {
      type: String,
    },
    info: {
      type: String,
    },
 },
  
  data()
  {
    return {
      uniq: null,
      options: [
        {value: true, text: "Да"},
        {value: false, text: "Нет"},
      ],
    }
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("select")
  },
            
}
</script>
