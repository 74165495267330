<template>
  <input :name=name :id=id :value=value />
</template>

<script>
const tplanTypes = {
    home: { name: "Домашний", order: 1 },
    business: { name: "Бизнес", order: 2 },
    free: { name: "Бесплатно", order: 3 },
    personal: { name: "Индивидуальный", order: 4 },
    "static-ip": { name: "Статический IP", order: 5 },
    iptv: { name: "IPTV", order: 6 },
    installment: { name: "Рассрочка", order: 7 },
    service: { name: "Служебный", order: 8 },
    archive: { name: "Архивный", order: 9 },
    "": { name: "Прочие", order: 10 },
}

function tplan(type)
{
  const key = type in tplanTypes ? type : ""
  return tplanTypes[key]
}

import darsan from "darsan"
import $ from "jquery"
import _ from "underscore"

export default {

  name: "RadixTariffInput",

  props: {
    name: String,
    id: String,
    value: String,
  },
  
  mounted()
  {
    this.ensure(["tplan"]).then( () =>
    {
      const grp = _.groupBy(this.$store.state.preload['/tplan'], rec => rec.type)
      const types = Object.keys(grp).sort((a,b) => tplan(a).order - tplan(b).order )
      const list = types.map(t => { 
        return {text: tplan(t).name, children: grp[t].sort().map(rec => { return {id: rec.entity, text: rec.display_name} })} 
      })
      
      const options = {
        dropdownCssClass: 'ui-dialog',
        data: { results: list },
      }

      try 
      {
        $(this.$el).select2(options)
        $(this.$el).on("change", ev => this.$emit("input", ev.val))
      }
      catch(e) { this.common.notifyError(e) }
    })
  },

  methods: {
  },

  watch: {
    value(val) { $(this.$el).select2("val", val) },
  },

}
</script>
