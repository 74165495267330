var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table table-bordered" }, [
    _c(
      "tbody",
      _vm._l(5, function(i) {
        return _c(
          "tr",
          { key: i },
          _vm._l(5, function(j) {
            return _c("td", { key: j }, [
              _c("div", { staticClass: "faux-cell" })
            ])
          }),
          0
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }