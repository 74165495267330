<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0">
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>

    <div :class="'col-sm-'+size">
      <input class="form-control" :list="'list' + uniq" :name=name :id=uniq :value=value :maxlength=maxlength type=phone autocomplete="off"
      v-maska:[maskaOpt]
      @change='$emit("input", undecorateValue($event.target.value))' />
      <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>

  </div>
</template>

<script>

import _ from "underscore"
import { vMaska } from "maska"

export default {
  name: "Phone",
  
  directives: { maska: vMaska },
  
  data()
  {
    return { 
       uniq: null,
       maskaOpt: {
        eager: true,
        mask: value => this.undecorateValue(value).startsWith("9") ? "+7 (###) ###-##-##" : "(###) ###-##-##",
      },

    }
  },
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    value: {
       type: String,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    error: {
      type: String,
    },
    info: {
      type: String,
    },
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("input")
  },
  
  methods: {
    undecorateValue(value)
    {
      return value.replace(/^\+7 /,"").replace(/\D/g,"")
    }
  },
  
}
</script>

<style scoped>
  input::-webkit-calendar-picker-indicator {
    display: none !important;
  }
</style>
