<template>
  <div v-if="border">
    <div class="panel panel-info">
      <template v-if="heading">
        <div class="panel-heading">
          <div style="display:flex;align-items:center;justify-content: space-between;">
            <div>
              <span v-if="icon" aria-hidden="true" class="glyphicon" :class="'glyphicon-'+icon">&thinsp;</span>
              {{title}}
            </div>

            <Toolbar :buttons=toolbar :addPrintScreen=true ref="toolbar" :spinning=spinning :low=lowToolbar 
              @toolbar-click=toolbarClick @print-screen=printScreen />
          </div>
        </div>				 
      </template>
      <div :class="{ 'panel-body': padding }"><slot></slot></div>
    </div>
  </div>
  
  <div :class="{ 'panel-body': padding }" v-else><slot></slot></div>
</template>

<script>

import Toolbar from "common/visual/Toolbar.vue"
import {printScreen} from "common"

export default {

  components: {Toolbar},

  props: {
    border: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "???",
    },
    heading: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
    toolbar: {
      type: Array,
    },
    lowToolbar: {
      type: Boolean,
      default: false,
    },
    spinning: {
      type: String,
    },
  },

  data()
  {
    return {
    }
  },
  
  methods: {
    
    toolbarClick(msg)
    {
      this.$emit("toolbar-click", msg)
    },
    
    printScreen()
    {
      printScreen(this.$el.getElementsByClassName("panel")[0])
    },
  },
  
  computed: {
  },  
}
</script>
