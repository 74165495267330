define([
  'common',
  'jquery',
  'underscore',
  'common/visual/visual',
  'navigation'
], function(common,$, _, visual,nav)
{
  return Object.create(visual).extend({

    layout: {},

    create: function(el,opt)
    {
      var me = this;
      visual.create.apply(me,arguments);
      
      me.modParams = me.modParams || {};
      if (!me.modParams.parent) me.modParams.parent = me;
      //console.log('создаюсь...Page', me.$el);
      
      me.current = {};

      me.renderFromTemplate(me.template);

      var promises = [];
      _.each(this.layout, function(val, panel)
      {
        var cont = me.$el.find("#"+panel+"-container");
        if (!cont.length) throw new Error("No container found: #"+panel+"-container");
      
        if (visual.isPrototypeOf(val))
        {
          // модуль visual
          promises.push(val.create(cont,me.modParams));
        }
        else if (_.isArray(val))
        {
          var tabpanel =  $("<div/>", {role: "tabpanel"});
          var tablist =   $("<ul />", {class: "nav nav-tabs", role: "tablist"});
          var tabs =      $("<div/>", {class: "tab-content"});
          
          tabpanel.append(tablist,tabs);

          val.forEach(function(rec)
          {
            var li = $("<li/>",{role: "presentation"});
            var a = $("<a/>",{
              href: "#"+panel+"-"+rec.name+"-container",
              "aria-controls": "home",
               role: "tab",
               "data-toggle": "tab",
               "data-direct-link": "true",
               title: rec.title,
            });
            a.append($("<span/>",{class: "glyphicon glyphicon-"+rec.icon, "aria-hidden": "true"}));
            a.append($("<span/>",{"aria-hidden": "true"}).html(rec.text) );
            
            li.append(a);
            tablist.append(li);
            
            a.on("show.bs.tab",function(ev) { me.tabShown.call(me,panel,rec.name) });
            a.on("hide.bs.tab",function(ev) { me.tabHidden.call(me,panel,rec.name) });
           
            tabs.append( $("<div/>",{
              role: "tabpanel", 
              class: "tab-pane", 
              id: panel+"-"+rec.name+"-container", 
              style: "padding-top: 4px",
            }));
          });
          
          cont.append(tabpanel);
        }
        else if (_.isObject(val))
        {
          // Оверлейные модули
          _.each(val, function(mod,name)
          {
            cont.append($("<div/>",{id: panel+"-"+name+"-container"}));
          });
        }
      });

      return common.when(promises);
    },
    
    ///////////////////////////////////////////////////////
    
    setState: function(state){
      var me = this;

      if (state) me.state = state;

      _.each(me.layout, function(val, panel)
      {
        //console.log("rendering", panel, val, me.state );
        if (visual.isPrototypeOf(val))
        {
          // обыкновенная панель
          // console.log("// обыкновенная панель");
          val.setState(me.state);
        }
        else if (_.isArray(val))
        {
          // панель табов
          me._tabSetState(panel,val);
        }
        else if (_.isObject(val))
        {
          // оверлейная панель
          // console.log("// оверлейная панель");
          me._ovlSetState(panel, val);
        }
      });

    },

    _ovlSetState: function(name, lay)
    {
      var me = this;

      var needs = me.state[name] || me.neededOverlay(me.state, name);
      //console.log("ovl needed",needs);
      var newMod = me.layout[name][needs];

      var old = me.current[name];
      //console.log("old ovl was",old, me.layout);
      me.current[name] = needs;

      if (old && old!=needs)
      {
        //if (old) console.log("detaching",old);
        if (old) me.layout[name][old].detach();
      }

      if (newMod.initialized)
      {
       //console.log("already init",needs);
        // Модуль уже инициализирован
        if (old && old!=needs)
        {
          newMod.attach();
          //console.log("attaching",needs);
        }
        
        newMod.setState(me.state);
      }
      else
      {
        // первый вызов модуля
        //console.log("init",name,needs);
        var cont = me.$el.find("#"+name+"-"+needs+"-container");
        if (!cont.length) throw "No container found: #"+name+"-"+needs+"-container";
        
        nav.promiseCreateModule(newMod, me.$el.find("#"+name+"-"+needs+"-container"), me.modParams)
          .done(function()
          {
            newMod.setState(me.state);
          });
      }
    },

    _tabSetState: function(name, tablist)
    {
      var me = this;

      var needs = me.state[name] || "";
      if (!_.findWhere(tablist,{name: needs})) needs = tablist[0].name;
      me.state[name] = needs;

      console.log("tab needed", name+"=",needs);
      me._tabModuleSetState(name,needs,true);
    },

    callChild: function()
    {
      var args = _.toArray(arguments);
      var modname = args.shift();
      var method = args.shift();
      
      var mod = this._findModule(modname, this.layout);
      if (!mod) return;
      
      var func = mod[method];
      if (!_.isFunction(func)) return;
      
      return func.apply(mod,args);
    },
    
    _findModule: function(name,obj)
    {
      var me = this;

      var keys = _.keys(obj);
      for(var i=0; i<keys.length; i++)
      {
        var k = keys[i];
        var mod = obj[k];
        if (k==name) return(mod);
        if (!visual.isPrototypeOf(mod)) 
        {
          var m = me._findModule(name,mod);
          if (m) return m;
        }
      }
      
      return null;
    },
    
    tabShown: function(panel,tab)
    {
      var f = _.findWhere(this.layout[panel], {name: tab});
      var mod = f.module;

      nav.changeURL({ [panel]: tab });
      this._tabModuleSetState(panel,tab,false);
    },
    
    tabHidden: function(panel,tab)
    {
      var f = _.findWhere(this.layout[panel], {name: tab});
      var mod = f.module;
      
      var vars = _.isFunction(mod.stateVars) ? mod.stateVars() : [];
      var diff = {};
      vars.forEach(function(el)
      {
        diff[el] = null;
      });
      nav.changeURL(diff);      
    },
    
    _tabModuleSetState(panel,tab,show)
    {
      var me = this;

      var f = _.findWhere(me.layout[panel], {name: tab});
      var mod = f.module;
      if (!mod)
      {
        throw "_tabModuleSetState: no module '"+tab+"' in panel '"+panel+"'";
      }
      if (mod.initialized)
      {
        // Модуль уже инициализирован
        mod.setState(me.state);
        if (show)
        {
          me.$el.find("a[href='#"+panel+"-"+tab+"-container']").tab("show");
        }
      }
      else
      {
        // первый вызов модуля
        console.log("init",panel,tab);
        var cont = me.$el.find("#"+panel+"-"+tab+"-container");
        if (!cont.length) throw "No container found: #"+name+"-"+needs+"-container";
        
        nav.promiseCreateModule(mod, me.$el.find("#"+panel+"-"+tab+"-container"), me.modParams)
          .done(function()
          {

            mod.setState(me.state);
            if (show)
            {
              me.$el.find("a[href='#"+panel+"-"+tab+"-container']").tab("show");
            }
          });
      }
    },
  
  }); // object
});
