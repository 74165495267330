var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-2", staticStyle: { "margin-bottom": "0px" } },
      _vm._l(4, function(i) {
        return _c("SkelSearchInput", { key: i })
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-10" }, [_c("SkelTable")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }