<template>
  <div style="margin-bottom: 5px;" class="form-group form-group-sm" :class='{"has-feedback": val.wildcard || val.implicit_wildcard}' >
    <label :for=val.name style="font-size: 12px; margin-bottom: 0px;">
    {{val.name_ru}}
    <span v-if="val.range"> (От, До)</span></label>

    <input v-if="val.type=='string'||val.type=='array'" v-model="model[val.name]" class='form-control' />
    
    <input v-if="val.type=='integer'" type="number" v-model.number="model[val.name]"  class='form-control' :min=val.min :max=val.max />

    <template v-if="val.type=='float'">    
      <input type="number" v-model.number="model[val.name]" step="any" class='form-control' :min=val.min :max=val.max />
    </template>
    
    <RadixDeviceInput v-if="val.type=='radix-device'" v-model="model[val.name]" class="form-control" />
    <RadixTariffInput v-if="val.type=='radix-tariff'" v-model="model[val.name]" class="form-control" />

    <div v-if="val.type=='compare'" class='input-group'>
      <span class="input-group-btn">
        <select class="btn btn-default compare-operator" v-model="model['_'+val.name+'-operator']" style="height: 30px">
          <option value='=' selected="selected">=</option>
          <option value='>'>&gt;</option>
          <option value='<'>&lt;</option>
         </select>
      </span>
      <input v-model="model[val.name]" class='form-control compare-value' />
    </div>

    <select v-if="val.type=='boolean'" v-model="model[val.name]" class='form-control'>
      <option value=""></option>
      <option value='TRUE'>Да</option>
      <option value='FALSE'>Нет</option>
    </select>

    <SimpleSelect2 v-if="val.type=='oneof'" v-model="model[val.name]" :options=val.values :withBlank="true" />

    <DatePicker v-if="val.type=='date'" v-model="model[val.name]" :withTime="!!val.time" :range="!!val.range" />

    <span v-if="val.wildcard" class="text-info form-control-feedback" 
      title="Можно использовать *" aria-hidden="true" style="font-weight: 900; top: 21px;">*</span>

    <span v-if="val.implicit_wildcard" class="text-info form-control-feedback" 
      title="Поиск по части слова" aria-hidden="true" style="font-weight: 900; top: 21px;">*</span>
  </div>

</template>

<script>

import RadixDeviceInput from "common/misc/RadixDeviceInput.vue"
import RadixTariffInput from "common/misc/RadixTariffInput.vue"
import DatePicker from "common/misc/DatePicker.vue"
import SimpleSelect2 from "common/visual/SimpleSelect2.vue"

export default {
    
  name: 'SearchPanel',
    
  components: {RadixDeviceInput, RadixTariffInput, DatePicker, SimpleSelect2},
  
  props: {
    val: Object,
    model: Object,
  },
}

</script>

<style scoped>
  .input-range-start.form-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-range-end.form-control {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  @media screen  and (max-device-width: 480px) and (orientation: portrait) {
    .search-panel-form {
      overflow-x: hidden;
      margin-bottom: 10px;
    }

    .search-panel-container {
    }
  }

  @media screen  and (min-device-width: 1024px) and (orientation: landscape)  {
    .search-panel-form {
      max-height: calc(100vh - 140px);
      overflow-x: hidden;
      margin-bottom: 10px;
    }

    .search-panel-container {
      max-height: 100vh;
    }
  }
</style>
