<template>
<div>
  <div class="faux-label" />
  <div class="faux-input" />
</div>
</template>

<script>
export default {
  name: "SkelSearchInput",
}
</script>

<style scoped>
.faux-label
{
  background: rgba(0,0,0,.12); 
  width: 6em; 
  height: 1em;
  margin-bottom: 0.5em; 
}

.faux-input
{
  background: rgba(0,0,0,.12); 
  width: 95%; 
  height: 2.1em;
  margin-bottom: 1em;
}
</style>