"use strict";
define([
    'jquery',
    'underscore',
    'navigation',
    'backboneRadix',
    'common/context/context.css',
], function($, _, navigation, Backbone, html){

    var contextmenu = function(){
	this.el = $('<div>').attr({ id: 'context-menu' });
	var that = this;

	function controlFunc(e){
	    if ( that.el.has(e.target).length === 0) that.hide();
	};

        $(function(){
	    $('body').append(that.el);
	});

	this.hide = function(){
	    this.el.hide(); 
	    this.view = false;

	    $(document).off('click', controlFunc);
	}
	this.show = function(e, data, options){
	    e.preventDefault();
	    var result, options = options || {};
	    
	    function render( data ){
	        var array = [];
			_.each(data, function(v){

			    var $elw = $('<li>', { class: 'context'}).html( '<a href="#">' + v.name + '</a>' );
	    	    $elw.click(function( el ){ 
			        if( _.isFunction( v.func ) ) v.func( e, el ); 
			        that.hide();
        	    });
			    array.push( $elw );
			});
			return array;
		}

	    if( _.isArray( data ) ){
		result = $('<ul>', { class: 'context dropdown-menu' }).html( render( data ) );
	    }else{
		result = data;
	    }

	    if( options.minWidth ){
		result.css({ 'min-width': options.minWidth });
		result.find('ul').css({ 'min-width': options.minWidth });
	    }

	    this.el.css({ left: e.pageX, top: e.pageY }).html( result ).show();
	    this.view = true;

	    $(document).one('click', controlFunc);
	    return this.el;
	}
    }

    return new contextmenu();
});
        