define(["jquery","underscore","moment", "common"], function($, _, moment, common)
{
  var darsan = {
    promiseToken: null,
    map: {},
    useSessionStorage: true,
    notify: alert.bind(window),

    promiseUserInfo: function() 
    {
      var me = this;
      if (!this.promiseToken)
      {
        return $.Deferred().reject("darsan.promiseToken is not defined").promise();
      }

      var storage = this.useSessionStorage ? sessionStorage : localStorage;
    
      var token = storage.getItem("radix-token");
      var token_expires = storage.getItem("radix-token-expires");
      
      if (token && token_expires && new Date().getTime() < (token_expires - 300) * 1000) 
      {
        var rec = {
          token: token,
          expires: token_expires,
          login: storage.getItem("radix-login"),
          cn: storage.getItem("radix-cn"),
          sip: storage.getItem("radix-sip"),
          cached: true,
        };
        if (storage.getItem("radix-pretend")) rec.pretend = storage.getItem("radix-pretend");

        return $.Deferred().resolve(rec).promise();
      } 
      else 
      { 
        var request;
        if (!request)
        {
          request = me.promiseToken()
          .then(function(data)
          {
            var expires = moment(data.expires).unix();
            if (expires*1000 < new Date().getTime())
            {
              alert("Получен ключ доступа с уже истекшей датой. Проверьте системное время на этом компьютере");
            }

            storage.setItem("radix-token", data.token);
            storage.setItem("radix-token-expires", expires);
            storage.setItem("radix-login", data.login);
            storage.setItem("radix-cn", data.cn);
            storage.setItem("radix-sip", data.sip);
            if (data.pretend) storage.setItem("radix-pretend", data.pretend);
            
            return data;
          })
          .fail(function(xhr)
          {
            console.log(xhr.responseText);
          })
          .always(function()
          {
            request = null;
          })
        }
        
        return request;
      }
    },

    promiseDarsanToken2: function() 
    {
      return this.promiseUserInfo();
    },
    
    removeToken: function()
    {
      var storage = this.useSessionStorage ? sessionStorage : localStorage;
      storage.removeItem('radix-token-expires');
      storage.removeItem('radix-token');
      storage.removeItem('radix-login');
      storage.removeItem('radix-cn');
      storage.removeItem('radix-sip');
      storage.removeItem('radix-pretend');
    },
    
    makeUrl: function(prefix, topic, url)
    {      
      var server = this._findServer(prefix, topic)
      if (!server) throw Error("darsan.makeUrl: cannot determine server URL for '"+topic+"'");
      
      server = server.replace(/\{entity\}/,topic);
      server = server.replace("darsan-darsan","darsan");
      return server + url;
    },
    
    _findServer(prefix, topic)
    {
      if (this.map[prefix] && this.map[prefix][topic]) return this.map[prefix][topic]
      
      if (config.additional_client_domains)
      {
        const el = config.additional_client_domains.find(el => el.name==prefix)
        if (el) return el.darsan.servers
      }

      if (config.additional_domain)
      {      
        const el2 = config.additional_domains.find(el => el.name==prefix)
        if (el2) return el2.darsan.servers
      }
      
      return config.darsan.servers
    },
    
    makeURL: function()
    {
      return this.makeUrl.apply(this,arguments);
    },

    _call: function(prefix, topic, url, params, extra={}) 
    {
      var me = this;

      var headers = extra.headers || {};

      return me.promiseDarsanToken2()
      .fail(err => common.notifyError("Не могу получить токен: "+ err))
      .then(function(rec) 
      {
        headers.Authorization = "Darsan2 " + rec.token;

        if (rec.pretend)
        {
          headers["X-Darsan-Pretend"] = rec.pretend;
        }
        
        var fullUrl =  me.makeUrl(prefix,topic,url);
        
        var args = {
          type: extra.method||"GET",
          headers: headers,
          data: params,
          beforeSend: function(jqXHR, settings) 
          {
            jqXHR.url = fullUrl;
          },
          context: me,
        };
        
        if (extra.useFormData)
        {
          args.cache = false;
          args.contentType = false;
          args.processData= false;
        }
        else if (extra.asJSON)
        {
          args.data = JSON.stringify(args.data);
          args.contentType = "application/json";
        }
        else
        {
          args.traditional = true;
        }

        const promise = $.ajax(fullUrl, args)
        if (!extra.mute)
        {
          promise.fail(xhr => me.err(xhr))
        }
        
        return promise.then(data => data)
      })
    },

   get: function(prefix, topic, url, params, extra={}) 
   {
     return this._call(prefix, topic, url, params, extra);
   },

   getm: function(prefix, topic, url, params, extra={}) 
   {
     extra.mute = true
     return this._call(prefix, topic, url, params, extra);
   },

   postm: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "POST";
     extra.mute = true
     return this._call(prefix, topic, url, params, extra);
   },

   post: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "POST";
     return this._call(prefix, topic, url, params, extra);
   },

   postJSON: function(prefix, topic, url, params, extra={}) 
   {
      extra.method = "POST";
      extra.asJSON = true;
      return this._call(prefix, topic, url, params, extra);
   },

   postJSONm: function(prefix, topic, url, params, extra={}) 
   {
      extra.method = "POST";
      extra.asJSON = true;
      extra.mute = true
      return this._call(prefix, topic, url, params, extra);
   },

   formData: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "POST";
     extra.useFormData = true;
     return this._call(prefix, topic, url, params, extra);
   },

   formDatam: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "POST";
     extra.useFormData = true;
     extra.mute = true
     return this._call(prefix, topic, url, params, extra);
   },
 
   formDataPut: function(prefix, topic, url, params, extra) 
   {
     extra = extra || {};
     extra.method = "PUT";
     extra.useFormData = true;
     return this._call(prefix, topic, url, params, extra);
   },

   put: function(prefix, topic, url, params, extra={}) 
   {
      extra.method = "PUT";
      return this._call(prefix, topic, url, params, extra);
   },

   putm: function(prefix, topic, url, params, extra={}) 
   {
      extra.mute = true
      extra.method = "PUT";
      return this._call(prefix, topic, url, params, extra);
   },

    putJSON: function(prefix, topic, url, params, extra) 
   {
      extra = extra || {};
      extra.method = "PUT";
      extra.asJSON = true;
      return this._call(prefix, topic, url, params, extra);
   },

   patch: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "PATCH";
     return this._call(prefix, topic, url, params, extra);
   },

   patchm: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "PATCH";
     extra.mute = true
     return this._call(prefix, topic, url, params, extra);
   },

   patchJSON: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "PATCH";
     extra.asJSON = true;
     return this._call(prefix, topic, url, params, extra);
   },

   delete: function(prefix, topic, url, params, extra={}) 
   {
     extra.method = "DELETE";
     return this._call(prefix, topic, url, params, extra);
   },

   deletem: function(prefix, topic, url, params, extra={}) 
   {
     extra = extra || {};
     extra.method = "DELETE";
     extra.mute = true
     return this._call(prefix, topic, url, params, extra);
   },

   deleteJSON: function(prefix, topic, url, params, extra)
   {
     extra = extra || {};
     extra.method = "DELETE";
     extra.asJSON = true;
     return this._call(prefix, topic, url, params, extra);
   },

   injectToBackbone: function(Backbone)
   {
     var me = this;
     Backbone.ajax = function (request) 
     {
       me.promiseDarsanToken2().then(function (rec) 
       {
         var headers = request.headers || {};
         headers.Authorization = "Darsan2 " + rec.token;
         if (rec.pretend)
         {
           headers["X-Darsan-Pretend"] = rec.pretend;
         }
         request.headers = headers;
         
         request.beforeSend = function(jqXHR, settings)
         {
           jqXHR.url = request.url;
         };

         return $.ajax(request);
       });
     };
   },
   
   consError: function(xhr)
   {
     const blank = "<br/>"
   
     let text = ""
     let textRu = ""
     let extra = ""

     if (xhr.statusText)
     {
       var ct = xhr.getResponseHeader("Content-Type");
       var resp = xhr.responseJSON;

       const isJsonError = ct && (ct.match(/application\/error\+json/) || ct.match(/application\/json/))
       
       if (resp && isJsonError)
       {
         textRu = resp.text_ru
         text = resp.text
       }
       else if (xhr.responseText)
       {
         text = _.escape(xhr.responseText.substring(0,400));
       }

       extra = xhr.url + blank + xhr.status + " " + xhr.statusText
     }
     else
     {
       text = xhr
     }

     extra += `${blank}[${user.login}]`
     
     return [xhr.status, text, textRu, extra]
   },
   
   errorText: function(xhr, blank="<br/>", skipUrl=false)
   {
     let text = ""

     if (xhr.statusText)
     {
       var ct = xhr.getResponseHeader("Content-Type");
       var resp = xhr.responseJSON;

       const isJsonError = ct && (ct.match(/application\/error\+json/) || ct.match(/application\/json/))
       
       if (resp && isJsonError)
       {
         text = resp.text_ru || resp.text;
       }
       else if (xhr.responseText)
       {
         text = _.escape(xhr.responseText.substring(0,400));
       }

       if (!skipUrl)
       {
         text = text + blank + xhr.url + blank + xhr.status +" "+xhr.statusText;
       }
     }
     else
     {
       text = "Ошибка: "+xhr+blank;
     }

     text += skipUrl ? "" : blank + `[${user.login}]`
     
     return text;
   },
   
   namedError: function(xhr)
   {
     var ct = xhr.getResponseHeader("Content-Type");
     var resp = xhr.responseJSON;

     const isJsonError = ct && ct.match(/application\/error\+json/) || ct.match(/application\/json/)
     if (resp && isJsonError)
     {
       const name = resp.name
       const text = resp.text_ru || resp.text
       if (name && text)
       {
         return [name, text]
       }
     }

     darsan.error(xhr)
   },
   
   err: function(xhr)
   {
     const [status, text, textRu, extra] = this.consError(xhr)
     if (status==400)
     {
        common.notify("", {type: "warning", title: textRu || text})
     }
     else
     {
       common.notify(extra, {type: "error", title: textRu || text})
     }
   },
   
   error: function(xhr)
   {
     darsan.err(xhr)
   },
   
  };
  
  return darsan;

});