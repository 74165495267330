var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.title
        ? _c("h4", [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "label label-success",
                staticStyle: { "font-size": "60%" }
              },
              [_vm._v(_vm._s(_vm.common.formatNumber(_vm.total)))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.divClass }, [
        _c(
          "table",
          {
            staticClass:
              "table table-striped table-bordered table-hover table-my-sorted",
            staticStyle: { "margin-bottom": "0px" }
          },
          [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.enabledColumns, function(c) {
                  return _c(
                    "th",
                    {
                      style: { width: c.width + "%" },
                      attrs: { name: c.name },
                      on: {
                        contextmenu: function($event) {
                          $event.preventDefault()
                          return _vm.openContextMenu.apply(null, arguments)
                        }
                      }
                    },
                    [
                      c.icon
                        ? _c("Icon", { attrs: { name: c.icon } })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(c.title) + "\n        "),
                      c.sort
                        ? _c(
                            "span",
                            { staticClass: "pull-right" },
                            [
                              _c("Icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: c.sort && _vm.sortField != c.name,
                                    expression: "c.sort && sortField!=c.name"
                                  }
                                ],
                                staticClass: "to-right",
                                staticStyle: { color: "lightgray" },
                                attrs: { name: "sort" }
                              }),
                              _vm._v(" "),
                              _c("Icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.sortField == c.name && _vm.sortAsc,
                                    expression: "sortField==c.name && sortAsc"
                                  }
                                ],
                                staticClass: "float-right",
                                staticStyle: { color: "gray" },
                                attrs: { name: "fa-arrow-down" }
                              }),
                              _vm._v(" "),
                              _c("Icon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.sortField == c.name && !_vm.sortAsc,
                                    expression: "sortField==c.name && !sortAsc"
                                  }
                                ],
                                staticClass: "float-right",
                                staticStyle: { color: "gray" },
                                attrs: { name: "fa-arrow-up" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.rows, function(rec) {
                return _c(
                  "tr",
                  { class: _vm.rowClass(rec) },
                  _vm._l(_vm.enabledColumns, function(c) {
                    return _c(
                      "td",
                      { class: _vm.cellClass(c, rec) },
                      [
                        _vm._t(
                          c.name,
                          function() {
                            return [_vm._v(_vm._s(rec[c.name]))]
                          },
                          { rec: rec }
                        )
                      ],
                      2
                    )
                  }),
                  0
                )
              }),
              0
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.dynamic
        ? _c(
            "vue-context",
            {
              ref: "menu",
              attrs: { closeOnClick: false, closeOnScroll: false, lazy: true }
            },
            _vm._l(_vm.columnsDef, function(c) {
              return _c("li", { staticClass: "menu" }, [
                _c("label", [
                  _c("input", {
                    attrs: { type: "checkbox" },
                    domProps: { checked: !c.hidden },
                    on: {
                      click: function($event) {
                        return _vm.toggleHidden(c.name)
                      }
                    }
                  }),
                  _vm._v("\n      " + _vm._s(c.title || c.name) + "\n    ")
                ])
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }