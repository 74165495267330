<template>
  <i :class=iconClass @click=onClick :title=popup />
</template>

<script>

export default {

  name: "Icon",
  
  props: {
    name: String,
    popup: String,
  },
  
  data()
  {
    return {
    }
  },
  
  methods: {
    onClick()
    {
      this.$emit("click")
    }
  },
  
  computed: {
    iconClass()
    {
      if (/^fa-/.test(this.name))
      {
        return {fa: true, [this.name]: true}
      }
      else
      {
        return {glyphicon: true, ["glyphicon-"+this.name]: true}
      }
   },    
  },
}
</script>