define([
  'jquery',
  'underscore',
  'backboneRadix',
  "collection-view",
], function($, _, Backbone, collectionView)
{
  return Backbone.View.extend({
  
    initialize: function(options)
    {
      var me = this;
      
      me.data = options.data || {};

      _.extend(this, _.pick(options, "rowTemplate", "tableTemplate", "data"));
      this.compiled = _.template(this.tableTemplate, null, {variable: "me"});
      
      var markup = this.compiled(this.data);
      this.$el.empty().append(markup);
      
      var ModelView = Backbone.TemplateView.extend({
        template: this.rowTemplate,
        tagName: "div",
        className: "list-group-item",
                          
        render: function()
        {
          me.data.model = this.model.toJSON();
          var markup = this.compiled(me.data);
          this.$el.empty().append(markup);
                                          
          if (this.model.get('disabled') == 1 || this.model.get('disable')){ // for disabled user
            this.$el.addClass("warning") ;
          }
          else{
            this.$el.removeClass("warning");
          }

          return this;
        },

        initialize: function (args) {
          Backbone.TemplateView.prototype.initialize.apply(this, args);
          this.listenTo(this.model,"change", function(model) { this.render(); });
        }
      });                                                                                              

      this.collView = new collectionView( {
        el: this.$el.find("ul"),
        selectable : true,
        // sortable: true,
        emptyListCaption : "Нет записей.",

        collection: this.collection,
        modelView: ModelView,
      });


      this.collView.on("sortStop", function(model) {
        me.trigger("orderChange", model);
      });
      
      
      this.collView.on("doubleClick", function(model) {
        me.trigger("edit", model);
      });

    },
    
    render: function()
    {
      this.collView.render();
    },
    
  });
});
