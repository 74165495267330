var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.title
      ? _c("h4", [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }),
          _vm._v(" "),
          _c(
            "small",
            {
              staticClass: "label label-success",
              staticStyle: { "font-size": "60%" }
            },
            [
              _vm.query
                ? _c("span", { attrs: { v0: "" } }, [_vm._v("Найдено: ")])
                : _vm._e(),
              _vm._v(_vm._s(_vm.common.formatNumber(_vm.total)))
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "btn-toolbar table-toolbar",
        staticStyle: { "margin-bottom": "5px", "margin-left": "0" },
        attrs: { role: "toolbar", "aria-label": "..." }
      },
      [
        _c("Toolbar", {
          ref: "toolbar",
          attrs: { buttons: _vm.toolbar },
          on: { "toolbar-click": _vm.toolbarClick }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-group pull-right", attrs: { role: "group" } },
          [
            _c("PagerView", {
              attrs: {
                total: _vm.total,
                current: _vm.currentPage,
                "per-page": _vm.perPage
              },
              on: { "set-page": _vm.setPage }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive" }, [
      _vm.ready
        ? _c(
            "table",
            {
              staticClass:
                "table table-striped table-bordered table-hover table-my-sorted",
              staticStyle: { "margin-bottom": "0px" }
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c(
                      "th",
                      {
                        staticStyle: { width: "9%" },
                        attrs: { name: "time_unix" }
                      },
                      [_vm._v("Дата")]
                    ),
                    _vm._v(" "),
                    _vm.showAdmin
                      ? _c("th", { attrs: { name: "admin" } }, [
                          _vm._v("Инициатор")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showClient
                      ? _c("th", { attrs: { name: "admin" } }, [_vm._v("UID")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showEntity
                      ? _c("th", { attrs: { name: "entity" } }, [
                          _vm._v("Объект")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.columns, function(c) {
                      return _c(
                        "th",
                        {
                          style: { width: c.width + "%" },
                          attrs: { name: c.name }
                        },
                        [
                          c.icon
                            ? _c("Icon", { attrs: { name: c.icon } })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(c.title) + "\n      ")
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.rows, function(rec) {
                  return _c("tr", { class: _vm.actionClass(rec.action) }, [
                    _c("td", [
                      _vm._v(_vm._s(_vm.common.fromUnixtime(rec.time_unix)))
                    ]),
                    _vm._v(" "),
                    _vm.showAdmin
                      ? _c("td", [
                          rec.admin.match(/^\$/)
                            ? _c(
                                "a",
                                { attrs: { href: `/worker/${rec.admin}` } },
                                [
                                  _c("Icon", { attrs: { name: "fa-desktop" } }),
                                  _vm._v(" " + _vm._s(rec.admin))
                                ],
                                1
                              )
                            : rec.admin.match(/^uid:/)
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: `/client/${_vm.getUid(rec.admin)}`
                                  }
                                },
                                [
                                  _c("Icon", { attrs: { name: "user" } }),
                                  _vm._v(" " + _vm._s(_vm.getUid(rec.admin)))
                                ],
                                1
                              )
                            : _c(
                                "a",
                                { attrs: { href: `/worker/${rec.admin}` } },
                                [_vm._v(_vm._s(rec.admin))]
                              )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showClient
                      ? _c("td", [
                          rec.uid
                            ? _c(
                                "a",
                                { attrs: { href: `/client/${rec.uid}` } },
                                [_vm._v(_vm._s(rec.uid))]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showEntity
                      ? _c("td", { attrs: { title: rec.entity } }, [
                          _vm._v(_vm._s(_vm.entityHumanName(rec)))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("Icon", {
                          attrs: {
                            name: _vm.actionIcon(rec.action),
                            title: _vm.actionHint(rec.action)
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isLong(rec)
                      ? _c(
                          "td",
                          [
                            _c(
                              "Flipper",
                              {
                                attrs: {
                                  shy: true,
                                  title: "               …                  "
                                }
                              },
                              [
                                _c("HistoryRow", {
                                  class: _vm.actionClass(rec.action),
                                  attrs: { row: rec }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "td",
                          [
                            _c("HistoryRow", {
                              class: _vm.actionClass(rec.action),
                              attrs: { row: rec }
                            })
                          ],
                          1
                        )
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }