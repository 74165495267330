<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniqueId class="control-label" :class="'col-sm-'+labelSize" >{{label}}</label>
      <div :class="'col-sm-'+size">
        <input class="form-control" :name=name :id=uniqueId />
        <Icon v-show="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
          style="cursor: pointer; pointer-events: auto;" @click.native=undo />      

     <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>
  </div>
</template>

<script>

import CooperMixin from "common/visual/cooper/CooperMixin.vue"
import $ from "jquery"
import darsan from "darsan"

export default {
  name: "LocationInput",
 
  mixins: [CooperMixin],
  
  data() 
  {
    return {
      element: "input",
    }
  },
  
  mounted()
  {
    const el = $(this.$el.querySelector("input"))
    el.select2({
      language: "ru",
      minimumInputLength: 2,
      initSelection: this.initSelection,
      query: this.query,
    })

    el.select2("val", this.value)
    el.on("change", ev => this.newValue(ev))
    el.on("select2-focus", () => this.setPrevious(this.localValue))
    el.on("select2-removing", () => this.setPrevious(this.localValue))
  },

  methods: {
    
    async query(query)
    {
      const data = await darsan.get(this.apiDomain, "geo", "/search", { what: query.term })
        .then( data =>
        {
           const results = data.map( el => 
           {
              const full = el.fullname || '[' + el.entity + ']';
              return { id: el.entity + '|' + full, text: full };
           });
          query.callback({ results, more: false });
        })
    },
    
    initSelection : function(element, callback) 
    {
      const value = $(element).val()
      if (!value) return ""
      
      const v = value.split(/\|/)
      callback({id: value, text: v[1]})
    },
    
    animatedElement()
    {
      return this.$el.querySelector("div.select2-container")
    },
    
    undo()
    {
      $(this.$el).find("input#"+this.uniqueId).select2("val", this.previous)
      this.previous = null
    },

  },    
  
  watch: {
    value(val) { $(this.$el).find("input#"+this.uniqueId).select2("val", this.value) }
  },
  
}

</script>
