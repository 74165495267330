<template>
  <select class="form-control" :multiple=multiple >
    <option v-if="withBlank" value=""></option>
    <option v-for="el in options" :key=el.value :value=el.value :selected="el.value == value">{{el.text}}</option>
  </select>
</template>

<script>
  import $ from "jquery"

  export default {
    name: 'SimpleSelect2',

    data () {
      return {
        element: 'select',
      }
    },

    mounted() {
      const el = $(this.$el)
      el.select2({ allowClear: this.withBlank })
      el.select2('val', this.value)

      el.on('change', ev => { this.$emit('input', ev.val) })
    },

    props: {
      options: {
        type: Array,
      },

      multiple: Boolean,

      withBlank: {
        type: Boolean,
        default: false,
      },

      value: {
        type: [ Number, String, Array ]
      },
    },

    watch: {
      value (val) { $(this.$el).select2('val', this.value) }
    },
  }
</script>
