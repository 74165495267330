// Packaging/modules magic dance.
(function (factory) {
    var L;
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['leaflet'], factory);

    } else if (typeof module !== 'undefined') {

        // Node/CommonJS
        L = require('leaflet');
        module.exports = factory(L);

    } else {

        // Browser globals
        if (typeof window.L === 'undefined')
            throw 'Leaflet must be loaded first';
        factory(window.L);

    }
}(function (L) {
"use strict";

 var icon = new L.DivIcon({ iconSize: new L.Point(8, 8), className: 'leaflet-div-icon leaflet-editing-icon' }); 	
 L.marker2 = L.Marker.extend({
 
 
 
 });

 var icon = new L.DivIcon({ iconSize: new L.Point(8, 8), className: 'leaflet-div-icon leaflet-editing-icon' }); 	
 L.polyline2 = L.Polyline.extend({
	initialize: function(e, o){

		L.Polyline.prototype.initialize.call(this, e, o);

		this._points = L.layerGroup();
		this._tmp_points = L.layerGroup();

		this._addPoints();
		this._addTmpPoints();


		this.on('add', function(){

			this._points.addTo( this._map );
			this._tmp_points.addTo( this._map );

		});
		this.on('remove', function(){

			this._map.removeLayer( this._points );
			this._map.removeLayer( this._tmp_points );

		});
	},
	addLatlng: function(e, o){
		L.Polyline.prototype.addLatlng.call(this, e, o);

		this._removePoints();
		this._addPoints();

		this._removeTmpPoints();
		this._addTmpPoints();
	},
	setLatLngs: function(e, o){
		L.Polyline.prototype.setLatLngs.call(this, e, o);

		this._removePoints();
		this._addPoints();

		this._removeTmpPoints();
		this._addTmpPoints();

	},
	_addPoints: function(){

		var array = this.getLatLngs();
		_.each(array, function(v,k){

			if( v.object ) return;
			var object = L.marker( v, { icon: icon, draggable: true });

			object.on('dragstart', function( e ){

				this._removeTmpPoints();		

				this.fire('dragstart', e.target);
			}, this);
			
			object.on('drag', function( e ){

				array[k] = e.target._latlng;
				L.Polyline.prototype.setLatLngs.call(this, array);

				this.fire('drag', e.target);

			}, this);

			object.on('dragend', function( e ){
				this._addTmpPoints();		

				this.fire('dragend', e.target);
			}, this);

			object.on('contextmenu', function( e ){
				if( k == 0 || k == array.length - 1 ) return;

				array.splice(k, 1);
				L.Polyline.prototype.setLatLngs.call(this, array);

				this._removePoints();
				this._addPoints();

				this._removeTmpPoints();
				this._addTmpPoints();

				this.fire('dragend', e.target);
			}, this);

			this._points.addLayer( object ); 

		}, this);
	},
	_removePoints: function(){
		this._points.clearLayers(); 
	},
	_addTmpPoints: function(){

		var array = this.getLatLngs();
		_.each(array, function(v,k){

			//Неактивные метки
			if( k == 0 ) return;

			var bounds = new L.LatLngBounds( [array[k-1], array[k]] );
			var object =  L.marker(bounds.getCenter(), { icon: icon, opacity: 0.5, draggable: true });

			object.on('dragstart', function( e ){

				var array = this.getLatLngs();
				array.splice( k, 0, e.target._latlng );
				L.Polyline.prototype.setLatLngs.call(this, array);

				this.fire('dragstart', e.target);
			}, this);

			object.on('drag', function( e ){

				var array = this.getLatLngs();
				array[ k ] = e.target._latlng;
				L.Polyline.prototype.setLatLngs.call(this, array);

				this.fire('drag', e.target);
			}, this);

			object.on('dragend', function( e ){

				this._removePoints();
				this._addPoints();

				this._removeTmpPoints();
				this._addTmpPoints();

				this.fire('dragend', e.target);

			}, this);

			this._tmp_points.addLayer( object ); 

		}, this);
	},
	_removeTmpPoints: function(){
		this._tmp_points.clearLayers(); 
	},
  });
}));