define(['jquery', 'underscore', 'darsan', 'preload', 'sysconf', '_lib/select_adv/select_adv'], function(
  $,
  _,
  darsan,
  preload,
  sysconf,
  select_adv
) {
  return {
    create: function(cont, name, input, options, model) {

	 var me = this;
	 options = options || {};

	 preload.ensure("tplan?static-ip").done(function(){

	    var prefix = sysconf.domainByUid((options||{}).uid) || config.domain;
	    var data = preload.get(prefix,"tplan?static-ip");

	    //Значение по умолчанию
	    if( !input.val() ) input.val( data.first().get('entity') );

	    select_adv(input, {
		data: _.map(_.sortBy(data.toJSON(), 'name'), function(v){ return { id: v.entity, name: v.entity+': '+v.name } }),
		onselect: function(opt, func) {
		    func(opt);
		    input.trigger('cooper:changed');
		}
	    });

	    if( model ){
		function animateSuccess(){
		    var el = input.next();
		    el.removeClass('animate_success').addClass('animate_success').delay(1000).queue(function(){
	        	$(this).removeClass('animate_success').dequeue(); 
		    });
		    model.off('error', animateFail);
		}

		function animateFail(){
		    var el = input.next();
		    el.removeClass('animate_fail').addClass('animate_fail').delay(1000).queue(function(){
	        	$(this).removeClass('animate_fail').dequeue(); 
		    });
		    model.off('sync', animateSuccess);
		}

		input.on('change', function(){
		    model.once('error', animateFail);
		    model.once('sync', animateSuccess);
		});
	    }

	    return input;
        });
     }
   }

});
