define([
  'highcharts',
  'common/visual/visual'
], function(highcharts,Visual)
{
  return Object.create(Visual).extend({
  
  create: function(el, options)
  {
    var me = this;
    Visual.create.apply(me,arguments);

    this.$el.highcharts(options);
    me.chart = this.$el.highcharts();
  },
  
  clear: function()
  {
    this.chart.series.forEach(function(s)
    {
      s.setData([],false);
    });
    
    this.chart.redraw();
  },
  
  // [ {name, point} ... ]
  addPoint: function(points,shift)
  {
    var me = this;
    
    points.forEach(function(p,name)
    {
      var series = me.chart.get(name);
      if (series) series.addPoint(p,false,shift); // redraw=false shift=true
    });
    
    me.chart.redraw();
  },

  setTitle: function(title,subtitle)
  {
    this.chart.setTitle({text: title},{text: subtitle});
  },
                      
  });
});
