var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-2", staticStyle: { "margin-bottom": "0px" } },
      [
        _c("SearchPanel", {
          attrs: {
            "meta-api-topic": _vm.metaApiTopic,
            "meta-api-path": _vm.metaApiPath,
            query: _vm.query
          },
          on: { "new-search": _vm.newSearch }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-10" }, [
      _c(
        "div",
        { staticStyle: { "overflow-y": "auto" } },
        [
          _c("Table", {
            ref: "table",
            attrs: {
              name: _vm.name,
              title: _vm.title,
              toolbar: _vm.toolbar,
              columns: _vm.columns,
              dynamic: _vm.dynamic,
              "quick-search": _vm.quickSearch,
              syncURL: _vm.syncURL,
              fetch: _vm.fetch,
              "api-domain": _vm.apiDomain,
              "api-topic": _vm.apiTopic,
              "api-path": _vm.apiPath,
              "row-class": _vm.rowClass,
              "cell-class": _vm.cellClass,
              state: _vm.state
            },
            on: {
              "load-query": _vm.loadQuery,
              "toolbar-click": _vm.toolbarClick
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function(_, name) {
                  return {
                    key: name,
                    fn: function(slotData) {
                      return [_vm._t(name, null, null, slotData)]
                    }
                  }
                })
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }