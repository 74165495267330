define([
  'underscore',
  'jquery',
  'common/table/table_adv',
  'common/visual/list',
  'darsan',
], function(_, $, tableAdv, List, darsan)
{  
  return Object.create(List).extend({
  
  create: function(el, options)
  {
    var me = this;
    List.create.apply(this, arguments);
    ["name","rowTemplate","tableTemplate"].forEach(el =>
    {
      if (!me[el]) throw Error(`dynatable: пропущено свойство '${el}' `);
    });
    
    var ths = $(me.tableTemplate).find("th");
    
    var rows = ths.map((i,el) =>
    {
      el = $(el);

      var id = el.attr("id");
      if (!id) throw Error(`dynatable: в заголовке ${el.prop("outerHTML")} нет атрибута id`);

      var rec = {
        id: id.replace(/^col-/,""),
        name: el.text().replace(/\s*$/,"") || el.attr("name"),
        thead: _.unescape(el.prop('outerHTML')),
      };
      
      if (el.attr("width")) rec.width = el.attr("width");
      
      return rec;

    }).toArray();
    
    var tds = $("<tr>"+me.rowTemplate+"</tr>").find("td");
    tds.each((i,el) =>
    {
      el = $(el);
      if (!rows[i]) throw Error(`dynatable: для элемента ${el.text()} нету заголовка в таблице`);
      rows[i].tbody = _.unescape(el.prop('outerHTML'));
    });
    new tableAdv({ 
      view: me.table.collView, 
      rows: rows,

      hash: { 
        get: function()
        {
          return userSettings["dynatable-"+me.name]  ||  rows.map(el => ({id: el.id, checked: 1}));
        }, 
        set: function(data)
        {
          darsan.putJSON("","darsan","/worker/"+(user.pretend||user.login)+"/config/radix/dynatable-"+me.name, data)
        }
      },
    });
  }
  });
});
                                                                                                                                            