define([
  'jquery',
  'underscore',
  'darsan',
  '_lib/select_adv/select_adv',
  'common/dialog/dialog',
  'geo/page/geo-page',
  'permission'
  ], function($, _, darsan, select_adv, dialog, geo, permission) {

  return {
    create: function(cont, name, input, options, model, prefix) {
      var me = this;
      options = options || {};

      var el = select_adv(input, {
        limit: 15,
        leftAddonTemplate: '<span class="fa fa-lg text-danger fa-map-marker"></span>',

        oninit: function(opt, func) {
          var me = this;

          var v = String(opt.id).split(/\|/);
          func({ id: v, name: v[1] });

          if (!v[0]) return;

          this.markLocation(v[0]);

          var marker = input.next().find('.select_adv_left_addon span.fa');

          if (permission.can_write('geo.data')){
            marker.on('click', (event) => {
              event.stopPropagation();

              geo.editCoordinates({
                locationId: v[0],
                onClose: () => {
                  marker.removeClass("text-danger").addClass("text-success")
                  me.markLocation(v[0]);
                }
              });
            });
          }
        },


        markLocation: (id) => {
          var marker = input.next().find('.select_adv_left_addon span.fa');

          prefix = prefix || '';

          darsan.get(prefix, 'geo', '/location/' + id)
          .done((data) => {
            if (data.geo && data.geo.coordinates) {
              marker.removeClass("text-danger").addClass("text-success")
            } else {
              marker.removeClass("text-success").addClass("text-danger")
            }
          });
        },

        onsearch: function(what, func) {
          darsan
            .get('', 'geo', '/search', { what: what })
            .done(function(data) {
              var results = _.map(data, function(el) {
                var full = el.fullname || '[' + el.entity + ']';
                return { id: el.entity + '|' + full, name: full };
              });
              func(results);
            });
        },

        onadvsearch: function(opt, func) {
          if (!opt.id) return;
          var v = String(opt.id).split(/\|/);

          darsan
          .get('', 'geo', '/location/' + v[0] + '/children')
          .done(function(data) {
            var results = _.map(data, function(el) {
              var full = el.fullname || '[' + el.entity + ']';
              return { id: el.entity + '|' + full, name: full };
            });

            func(results);
          });
        },

        onselect: function(opt, func) {
          func(opt);
          input.trigger('cooper:changed');
        }
      });

      if (model) {
        function animateSuccess() {
    		  var el = input.next();

    		  el.removeClass('animate_success').addClass('animate_success').delay(1000).queue(function() {
            $(this).removeClass('animate_success').dequeue(); 
          });

    		  model.off('error', animateFail);
        }

        function animateFail() {
    		  var el = input.next();

    		  el.removeClass('animate_fail').addClass('animate_fail').delay(1000).queue(function() {
            $(this).removeClass('animate_fail').dequeue(); 
    		  });

    		  model.off('sync', animateSuccess);
        }

        input.on('change', function() {
    		  model.once('error', animateFail);
    		  model.once('sync', animateSuccess);
      });
  	}
  
    return input;
  },

  };
});
