<template>
  <div class="faux-label" />
</template>

<script>
export default {
  name: "SkelLabel",
}
</script>

<style scoped>
.faux-label
{
  background: rgba(0,0,0,.12); 
  width: 100%; 
  height: 2em;
  margin-bottom: 1em; 
}

</style>