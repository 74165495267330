define([
  'common',
  'jquery',
  'underscore',
  'common/visual/visual',
  'allowed',
  'navigation',
], function(common,$, _, Visual, addMod, nav)
{
  return Object.create(Visual).extend({

    layout: {},
    currentMod: null,

    create: function(el,opt)
    {
      var me = this;
      Visual.create.apply(me,arguments);
      
      if (!me.name) throw new Error("No 'name' attribute");
      if (!me.layout) throw new Error("No 'layout' attribute");
      
      me.layout = addMod.filterLayout(me.layout);

      if (!me.layout.length) throw new Error("All layout items are disallowed by permissions");
      
      me.modParams = me.modParams || {};
      if (!me.modParams.parent) me.modParams.parent = me;
      //console.log('creating overlay...', me.$el);
      
      me.current = "";

      me.renderFromTemplate(this.template,{me: me});
    },
    
    findModuleIndex: function(name)
    {
      return common.findKeyWhere(this.layout, el => (el.name||el.module.name)==name);
    },
    
    generateTitle: function(el) 
    {
      var icon = el.icon || el.module.icon;
      var text = el.text || el.module.title;

      var s = "";      
      if (icon && icon.substring(0,3)=="fa-")
      {
        s += '<span class="fa '+icon+'" aria-hidden="true"/></span>\n';
      } 
      else if (icon)
      {
        s += '<span class="glyphicon glyphicon-'+icon+'" aria-hidden="true"/></span>\n';
      }
      
      s += '<span aria-hidden="true" class="text">'+text+'</span>';
      
      return s;
    },
                          
    ///////////////////////////////////////////////////////
    
  }); // object
});
