<template>
  <div>
    <h5 @click=toggle>{{title}}&nbsp; &nbsp; <Icon :name=iconName /></h5>
    <div v-show=opened >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Collapse",
  
  props: {
    title: String,
  },
  
  data() {
    return {
      opened: false,
    }
  },
  
  methods: {
    toggle()
    {
      this.opened = !this.opened
    }
  },
  
  computed: {
    iconName()
    {
      return this.opened ? "chevron-up" : "chevron-down"
    }
  }
  
}
</script>

