define([
  'jquery',
  'underscore',
  'moment',
  'backboneRadix',
  'common',
  'common/table/collection-view',
  "permission"
], function($, _, moment, Backbone, common, collectionView, perm)
{
  return Backbone.View.extend({

    initialize: function(options)
    {
      var me = this;

      me._extra = {};      
      me.data = options.data || {};

      _.extend(this, _.pick(options, "rowTemplate", "tableTemplate", "data", "extraRender", "rowClass"));
      this.compiled = _.template(this.tableTemplate,null,{variable: "me"});
      
      var markup = this.compiled(this.data);
      this.$el.empty().append(markup);

      var ModelView = Backbone.TemplateView.extend({
        template: this.rowTemplate,
        tagName: "tr",
                          
        render: function()
        {
          me.data.model = this.model.toJSON();
          me.data.common = common;
          me.data.moment = moment;
          me.data.extra = me._extra;
          var markup = this.compiled({me: me.data, perm: perm, common: common});
          this.$el.empty().append(markup);

          if (me.rowClass) {
            if (this.model.get(me.rowClass.modelAttr) == me.rowClass.attrValue) {
              console.log(this.$el);
              this.$el.addClass(me.rowClass.class) ;
            }
          }

          return this;
        },

        initialize: function (args) {
          Backbone.TemplateView.prototype.initialize.apply(this, args);
          this.listenTo(this.model,"change", function(model) { this.render(); });
        }
      });                                                                                              
      
      this.collView = new collectionView( {
        el: this.$el.find("table"),
        modelView: ModelView,
        selectable : true,
        collection: this.collection,
        extraRenderCollView: this.extraRender
      });
    },
    
    render: function(params)
    {
      this.collView.render(params);
    },
    
    extra: function(key, val)
    {
      this._extra[key] = val;
    },
    
  });
});
