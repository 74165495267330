define(
  [
    'jquery',
    'underscore',
    'navigation',
    'backboneRadix',
    'text-loader!common/dialog/layout.tpl',
  ],
  function($, _, navigation, Backbone, html) {
    'use strict';
    return {
	position: function( pt ){
	    var w = $(window).width(), h = $(window).height();
	    var wp = { id: 'dialog', width: 'auto', height: 'auto' };

	    if( w < 1000 ){
		wp.width = w - 20;
		wp.height = h - 20;
		setTimeout(function(){$('#'+wp.id).css({ overflow: 'auto' }).closest('.ui-dialog').css({ position: 'fixed', top: '10px', left: '10px' }); }, 200);
	    }else{
		if( pt ) wp.height = h - 40;
		setTimeout(function(){$('#'+wp.id).css({ overflow: 'auto' }).closest('.ui-dialog').css({ position: 'fixed', top: '10px' }); }, 200);
	    }
	    return wp;
      },
      show: function (title, html, options) {
        if (!options) {
          options = {};
        }
        var dialogUI = $('<div />', {
          title: title || '-',
          id: options.id || '',
          'data-dialog-assignment': options['data-dialog-assignment'],
        }).append(html);

	dialogUI.dialog({
          autoOpen: false,
//	  overflow: options.overflow||'hidden',
          top: options.top || 'auto',
          left: options.left || 'auto',
          width: options.width || '80%',
          height: options.height || 'auto',
          position: options.position,
          maxWidth: '90%',
          close: function() {
            dialogUI.trigger('close');
            dialogUI.dialog('destroy');
            dialogUI.remove();

            if (options.onClose && typeof(options.onClose) === 'function') {
              options.onClose();
            }
          },
        });


	dialogUI.parent().draggable({ cancel: ".ui-dialog-title", handle: ".ui-dialog-titlebar" });
        dialogUI.dialog('open');
        return dialogUI;
      },

      showModule: function (mod, options, state) {
        if (mod.dialogOpened) {
          return;
        }

        if (_.isUndefined(state)) {
          state = options;
          options = undefined;
        }

        state = state || {};
        options = options || {};

        var dialogUI = $('<div />', {
          title: '',
          id: options.id || '',
          'data-dialog-assignment': options['data-dialog-assignment'],
        }).append(html);
        
        $("body").remove("div#"+options.id)
        
        $('body').append(dialogUI);
        var body = dialogUI;
        var proxy = _.extend(
          {
            close: function() {
	          dialogUI.dialog('close'); // .close()
            }
          },
          Backbone.Events
        );
        navigation.promiseCreateModule(mod, body, options).then(function() {
          if (!mod.detached) {
            mod.detach();
          }

          mod.attachTo(body);
          mod.setState(state);

          if (!mod.dialogOpened) {
            dialogUI.attr('title', mod.title);
            dialogUI.addClass(state);

            dialogUI.dialog({
	      draggable: false,
              autoOpen: false,
//	      overflow: options.overflow||'hidden',
              top: options.top || 'auto',
	      left: options.left || 'auto',
    	      width: options.width || '80%',
              height: options.height || 'auto',
              position: options.position,
              maxWidth: '90%',
              close: function() {
                mod.detach();
                mod.dialogOpened = false;
                dialogUI.dialog('destroy');
                dialogUI.remove();
        	body.remove();
                proxy.trigger('close');
              }
            });
          }

	  dialogUI.parent().draggable({ cancel: ".ui-dialog-title", handle: ".ui-dialog-titlebar" });
	  dialogUI.dialog('open');
          mod.dialogOpened = true;

          if (options.zIndex)
          {
            $(dialogUI).parent().css('z-index', options.zIndex)
          }
        });

    return proxy;
      },

      close: function(id) {
        var el = $('#' + id);
        if (!el) { return };

        el.dialog('close');
      },

      closeAllBySelector: function(selector) {
        $(selector).each(function(i, dialog) {
          $(dialog).dialog('close');
        });
      }
    };
  }
);
