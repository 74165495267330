<template>
<div>
  <h4 v-if="title"><span v-html="title"></span> <small style="font-size: 60%" class="label label-success"><span v-if="query">Найдено: </span>{{common.formatNumber(total)}}</small></h4>
  <div class="btn-toolbar table-toolbar" role="toolbar" aria-label="..." style="margin-bottom: 5px; margin-left: 0;">
    <Toolbar ref="toolbar" :buttons=fullToolbar @toolbar-input=toolbarInput @toolbar-click=toolbarClick />
    <div class="btn-group pull-right" role="group" >
      <PagerView :total=total :current=currentPage :per-page=perPage @set-page=setPage />
    </div>
  </div>
  <slot name="Operations"></slot>
  <div class="table-responsive">
  <table class="table table-striped table-bordered table-hover table-my-sorted" style="margin-bottom: 0px;" >
    <thead>
    <tr>
      <th v-for="c in enabledColumns" 
       :name=c.name :style="{width: c.width+'%'}" 
       @click=toggleSort(c.name) 
       @contextmenu.prevent="openContextMenu"
      >
        <Icon :name=c.icon v-if="c.icon" :popup=c.popup /> {{c.title}}
        <span v-if="c.sort" class="pull-right">
          <Icon style="color: lightgray" class="to-right" v-show="c.sort && sortField!=c.name" name="sort" />
          <Icon style="color: gray" class="float-right" v-show="sortField==c.name && sortAsc" name="fa-arrow-down" />
          <Icon style="color: gray" class="float-right" v-show="sortField==c.name && !sortAsc" name="fa-arrow-up" />
        </span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="rec in rows" :class=rowClass(rec)>
        <td v-for="c in enabledColumns" :class=cellClass(c,rec) >
          <slot :name=c.name :rec=rec >{{ rec[c.name] }}</slot>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
  
  <vue-context ref="menu" :closeOnClick=false :closeOnScroll=false :lazy=true v-if=dynamic >
  <li v-for="c in columnsDef" class="menu" >
    <label>
      <input type="checkbox" :checked=!c.hidden @click="toggleHidden(c.name) " />
      {{ c.title || c.name }}
    </label>
  </li>
  </vue-context>
</div>
</template>

<script>
  import _ from "underscore"
  import {eventBus} from "main"

  import darsan from "darsan"
  import $ from "jquery"
  
  import {changeURLParam} from "navigation"
  import {notifyHttpError} from "common"

  import Toolbar from "common/visual/Toolbar.vue"
  import PagerView from "common/visual/PagerView.vue"
  
  import { VueContext } from 'vue-context'

  export default {
    
    name: "Table",
    
    components: {Toolbar, PagerView, VueContext},
    
    props: {
      // Внутреннее имя таблицы, напр. для сохранения макета в настройках пользователя
      name: {
        type: String,
        required: true,
      },
      title: String,
      toolbar: {
        type: Array,
        default: () => []
      },
      columns: {
        type: Array,
        default: () => [],
      },
      // Добавляет редактирование макета: перенос колонок, изменение ширины, добавление и удаление
      dynamic: {
        type: Boolean,
        default: false,
      },
      // Добавляет в панель кнопок поле для быстрого поиска
      quickSearch: {
        type: String,
        default: null,
      },
      // Начальное состояние таблицы (строка в виде ?page=1&query=xxx&sort=-a)
      state: {
        type: String,
        default: null,
      },
      // Синхронизировать URL с состоянием компонента 
      // (менять в урл параметры сортировки, страницы, запроса ?page=1&query=xxx&sort=-a)
      syncURL: {
        type: Boolean,
        default: true,
      },
      // Функция получения данных (если не подходит стандартная)
      fetch: [Function, String],
      
      // Параметры доступа к данным (если используется функция получения по умолчанию)
      apiDomain: {
        type: String,
        default: "",
      },
      apiTopic: String,
      apiPath: String,
      // Раскраска строк
      rowClass:{
        type: Function,
        default: () => null,
      },
      // Раскраска ячеек
      cellClass:{
        type: Function,
        default: () => null,
      },
    },
    
    data()
    {
      return {
        rows: [],
        columnsDef: this.joinColumnsDefinitions(),
        sortField: null,
        sortAsc: true,
        query: null,
        currentPage: 0,
        perPage: +this.$store.state.userSettings.rowsPerPage || 25,
        total: 0,
        fullToolbar: this.toolbar,
        fetchFunction: this.defaultFetchRows,
        fetchRows: null,
        isActivated: true,
      }
    },
    
    created()
    {
      if (this.quickSearch)
      {
        this.fullToolbar.push({input: true, placeholder: "Быстрый поиск", name: "_quickSearch"})
      }
      
      // Событие от SearchPanel
      this.$on("setQuery", q => 
      {
        this.query = q
        this.currentPage = 1 
      })
      
      this.$on('setPage', page => this.currentPage = page)
      
      // Требование перезагрузки таблицы
      this.$on("reloadTable", () => this.fetchRows())
      
      this.initFromState()
      
      this.$store.watch(
        (state, getters) => state.refreshTable[this.name],
        (val) => 
        {
          if (val==1) this.fetchRows()
        })
    
      if (typeof this.fetch == "string" && this.fetch == "simple")
      {
        this.fetchFunction = this.simpleFetchRows
      }
      else
      {
        this.fetchFunction = this.fetch || this.defaultFetchRows   
      }
      
      this.fetchRows = _.throttle(this.eagerFetchRows, 300, {leading: false})
    },

    mounted()
    {
      if (this.dynamic) this.decorateTable()
    },
    
    activated()
    {
      this.isActivated = true
    },
    
    deactivated()
    {
      this.isActivated = false
    },
    
    computed: {
      enabledColumns()
      {
        return this.columnsDef.filter(el => !el.hidden)
      },
    },
    
    methods: {
      toggleSort(name)
      {
        const col = this.columnsDef.find(el => el.name==name)
        if (!col || !col.sort) return
        
        if (name==this.sortField)
        {
          this.sortAsc = !this.sortAsc
        }
        else
        {
          this.sortField = name
          this.sortAsc = true
        }
      },
      
      setPage(p)
      {
        this.currentPage = p
      },
      
      eagerFetchRows()
      {
        if (!this.isActivated) return

        this.fetchFunction({
          from: (this.currentPage-1)*this.perPage, 
          count: this.perPage, 
          sortField: this.sortField, 
          sortAsc: this.sortAsc, 
          query: this.query
        })
        .then(rec => 
        {
          this.total = +rec.total
          this.rows = rec.data
          
          this.$store.commit("wasRefreshed", this.name)
        })
        .catch(res => { console.log(res); notifyHttpError(res) })
      },
      
      defaultFetchRows(args)
      {
        let sort = args.sortField
        if (args.sortField && !args.sortAsc) sort = "-" + sort
  
        const params = {
          from: args.from,
          count: args.count
        }
  
        if (sort) params.sort = sort
        if (args.query) params.query = args.query
  
        return darsan.get(this.apiDomain, this.apiTopic, this.apiPath, params)
      },
      
      // упрощенная функция, если запрос не возвращает страниц
      simpleFetchRows(args)
      {
        return darsan.get(this.apiDomain, this.apiTopic, this.apiPath)
          .then(list => ({data: list, total: list.length}))
      },
      
      toggleHidden(name)
      {
        const col = this.columnsDef.find(el => el.name==name)
        if (!col) return
        this.$set(col, "hidden", !col.hidden)
        this.saveColumns()
      },
      
      openContextMenu(event)
      {
        if (this.dynamic) this.$refs.menu.open(event)
      },
      
      saveColumns()
      {
        darsan.putJSON("","darsan","/worker/"+
          (this.$store.state.user.pretend||this.$store.state.user.login)+
          "/config/radix/dynamic-table-"+this.name, this.columnsDef)
      },

      initFromState()
      {
        const params = new URLSearchParams(this.state)

        this.query = params.get("query")
        this.$emit("load-query", this.query)

        const p = params.get("page")
        const s = params.get("sort")
        
        this.currentPage = p ? +p : 1
        
        if (s)
        {
          const res = s.match(/^(-?)(.*)/)
          this.sortField=res[2]
          this.sortAsc = res[1] !== "-"
        }
      },
      
      decorateTable()
      {
        const me = this
        // Делаем заголовок таблицы перетаскиваемым и меняющим размер
        $(this.$el).find("th")
        .resizable({
          handles: "e",
          stop: function( event, ui )
          {
            const newW = ui.size.width
            const tableW = me.$el.getElementsByTagName("table")[0]
            const name = ui.element.attr("name")
            const col = me.columnsDef.find(el => el.name==name)
            me.$set(col, "width", (newW/tableW.offsetWidth*100).toFixed(2))
            me.saveColumns()
          }
        })
        .draggable({
          opacity: 0.85,
          axis: 'x',
          zIndex: '1',
          revert: true,
        })
        .droppable({
          drop(ev, ui)
          {
            const srcName = ui.draggable.attr("name")
            const dstName = ev.target.getAttribute("name")
            const srcIndex = me.columnsDef.findIndex(el => el.name==srcName)
            if (srcIndex==-1) return
            const deleted = me.columnsDef.splice(srcIndex, 1)
            const dstIndex = me.columnsDef.findIndex(el => el.name==dstName)
            me.columnsDef.splice(dstIndex, 0, deleted[0])
            me.saveColumns()
          } 
        })
      },
      
        toolbarInput(msg)
        {
          if (this.quickSearch && msg.from=="_quickSearch")
          {
            if (msg.value)
            {
              this.query = `${this.quickSearch} LIKE "*${msg.value}*"`
            }
            else
            {
              this.query = null
            }
          }
        },
        
        toolbarClick(msg)
        {
          this.$emit("toolbar-click", msg)
        },
        
        // соединяет определения, взятые из сохраненного конфига с определениями, 
        // взятыми из определения колонок при вызове таблицы
        // из определения берутся только sort и title
        // нужно, чтобы последующие правки колонок в файлах *.vue учитывались, даже если есть сохраненный конфиг
        joinColumnsDefinitions()
        {
          const current = this.$store.state.userSettings["dynamic-table-"+this.name]
          const definitions = this.columns
          
          if (!(current && current.length)) return definitions
          
          for (const rec of current)
          {
            const def = definitions.find( el => el.name==rec.name )
            if (def)
            {
              rec.title = def.title
              if ("sort" in def) rec.sort = def.sort
            }
          }
          
          // переносим колонки, которых нет в current
          for (const rec of definitions)
          {
            const curr = current.find( el => el.name==rec.name )
            if (!curr)
            {
              current.push(rec);
            }
          }
          
          return current
        },

    },
    
    watch: {
      state: function(val) { this.initFromState(); this.fetchRows() },
      query: function(val) 
      { 
        if (this.syncURL) changeURLParam("query", val || null) 
        this.fetchRows()
        if (!val || !val.match(new RegExp(`^${this.quickSearch} LIKE `)))
        {
          this.$refs.toolbar.$emit("clear-input", {name: "_quickSearch"})
        }
      },
      currentPage: function(val) { if (this.syncURL) changeURLParam("page", val!=1 ? val : null); this.fetchRows() },
      sortAsc: function(val) { if (this.syncURL) changeURLParam("sort", (val ? "" : "-")+this.sortField); this.fetchRows() },
      sortField: function(val) { if (this.syncURL) changeURLParam("sort", (this.sortAsc ? "" : "-")+val); this.fetchRows() },
    },

  }
  
</script>

<style scoped>
  table {
    max-width: 99.5%;
  }

  table.collection-list tr.selected {
    color: rgb(51, 51, 51);
    background-color: #d9edf7;
  }

  .menu {
    padding-left: 7px;
    padding-right: 7px;
  }

  @media screen  and (max-device-width: 480px) and (orientation: portrait) {
    .table-responsive {

    }

    .table-toolbar {
      min-height: 80px;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      margin-bottom: 5px;
      margin-left: 0px;
      align-items: flex-start;
    }
  }

  @media screen  and (min-device-width: 1024px) and (orientation: landscape)  {
    .table-responsive {
      height: calc(100vh - 160px);
    }
  }

@import '~vue-context/dist/css/vue-context.css'
</style>
