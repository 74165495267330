var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { role: "tabpanel" } }, [
    _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
      [
        _vm._l(_vm.visibleTabs, function(t) {
          return _c(
            "li",
            { staticClass: "nav-item", attrs: { role: "presentation" } },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: {
                    href: "#" + t.name,
                    role: "tab",
                    "data-toggle": "tab",
                    "data-direct-link": "true"
                  }
                },
                [
                  _c("Icon", { attrs: { name: t.icon } }),
                  _vm._v("  \n          "),
                  t.subpath == ""
                    ? [_c("b", [_vm._v(_vm._s(_vm.infoTabTitle || t.text))])]
                    : [_vm._v(_vm._s(t.text))]
                ],
                2
              )
            ]
          )
        }),
        _vm._v(" "),
        _vm.restTabs.length
          ? _c(
              "li",
              { staticClass: "dropdown", attrs: { role: "presentation" } },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "dropdown-menu",
                    staticStyle: { "border-top-color": "transparent" }
                  },
                  _vm._l(_vm.restTabs, function(r) {
                    return _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            href: "#" + r.name,
                            role: "tab",
                            "data-toggle": "tab",
                            "data-direct-link": "true"
                          }
                        },
                        [
                          _c("Icon", { attrs: { name: r.icon } }),
                          _vm._v("  " + _vm._s(r.text) + "\n\t  ")
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ]
            )
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-content", staticStyle: { "padding-top": "8px" } },
      [
        _c(
          "div",
          { staticClass: "tab-panel", attrs: { role: "tabpanel" } },
          [
            _c(
              "keep-alive",
              [
                _c(_vm.currentTab, {
                  tag: "component",
                  attrs: { state: _vm.state }
                })
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        staticStyle: {
          "border-bottom-color": "transparent",
          cursor: "pointer"
        },
        attrs: { "data-toggle": "dropdown", "data-target": "#" }
      },
      [_vm._v("\n        Ещё "), _c("b", { staticClass: "caret" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }