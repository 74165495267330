<template>
  <div class="list-group">
    <div class="row" v-for="(item,i) in items" :key=item.entity>
      <GenInput :size=9 :label-size=2 attr=value :attribute="findKind(item.type)" :apiDomain=apiDomain :apiTopic=apiTopic :apiPath="item.entity" v-model="items[i].value" />
      <Icon name="trash" @click="delItem(i)" />
    </div>
    <div class="row">
      <div class="col-12 pull-right" style="margin-right: 2em; margin-top: 1em;">
        <div class="dropdown">
           <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" :id=uniqueId data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
             Добавить <span class="caret"></span>
           </button>
           <ul class="dropdown-menu" :aria-labelledby=uniqueId>
             <li v-for="k in kinds" :key=k.value @click="addItem(k)" class="arrow">&nbsp;<Icon :name=k.icon />&nbsp;&nbsp;{{k.text}}</li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "underscore"
import darsan from "darsan"
import GenInput from 'common/visual/cooper/GenInput.vue';

export default {
  name: 'Dictionary',
  
  components: {GenInput},
  
  props: {
    baseUrl: String,
    apiDomain: String,
    apiTopic: String,
    kinds: Array,
  },
  
  data() {
    return {
      items: [],
    }
  },
  
  async mounted()
  {
    this.items = await darsan.get(this.apiDomain, this.apiTopic, this.baseUrl)
  },
  
  methods:
  {
    addItem(kind)
    {
      darsan.post(this.apiDomain, this.apiTopic, this.baseUrl, {type: kind.value, value: ""})
      .then(res =>
      {
        this.items.splice(this.items.length-1, 0, res)
      })
    },
    
    delItem(i)
    {
      darsan.delete(this.apiDomain, this.apiTopic, this.items[i].entity)
      .then(res =>
      {
        this.items.splice(i, 1)
      })
    },
    
    findKind(type)
    {
      return this.kinds.find(el => type==el.value)
    },
    
  },
  
};
                                                              
</script>


<style scoped>
.arrow 
{
  cursor: pointer;
}
</style>