define([
  "underscore", 
  "jquery", 
  "backboneRadix", 
  "common",
  "permission",
  
  "text-loader!common/cooper/generic.tpl",
  "text-loader!common/cooper/select.tpl",
  "text-loader!common/cooper/checkbox.tpl",
  "text-loader!common/cooper/input.tpl",
  "text-loader!common/cooper/textarea.tpl",
], function(_, $, Backbone, common, perm,
            genericTpl, selectTpl, checkboxTpl, inputTpl, textareaTpl)
{

  var o = {};
  o._Generic = Backbone.TemplateView.extend({
  
    genericEvents: {
      "click .undo-text": "undo",
    },
    
    oldValue: null,
    undoValue: null,
    
    initialize: function(options)
    {
      var me = this;
      
      me.serial = Math.floor( Math.random()*100000 );
      me.innerTemplate = _.template(me.template,null);
      me.template = genericTpl;
      me.$input = null;
      
      _.extend(me,_.pick(options,"label","l","i","model","options","optionsColl","name", "default", "defaultName"));
      if (options.optionscoll) me.optionsColl = options.optionscoll;
      
      me.events = _.extend({}, me.genericEvents, me.events);

      Backbone.TemplateView.prototype.initialize.apply(this,arguments);
    },
    
    editBegin: function()
    {
      this.oldValue = this.getValue();
    },
    
    editEnd: function(options)
    {
       var me = this;
       options = options || {};
    
       me.clearAnimation();
  
       var value = me.getValue();
       var toSave = {};
       toSave[me.name] = value;
       
       var group = me.$(".form-group");
       var helper = me.$(".help-input-"+me.serial);
  
       group.removeClass('has-error');
       if (!options.undo && value == me.oldValue) return;
                                      
       me.model.save(toSave, {patch: true}).done(function(model)
       {
         helper.addClass("hidden");
         me.animateSuccess();
                                                   
         me.undoValue = me.oldValue;
         if (me.$input.is(":focus")) me.$input.blur();
         
         if (options.undo)
         {
           me.$el.find(".undo-text").addClass('hidden');
         }
         else
         {
           me.$el.find(".undo-text").removeClass('hidden');
         }
         
         me.trigger("cooper:save:success",model.get(me.name));
       })
       .fail(function(data, xhr)
       {
         group.addClass('has-error');
         var err = darsan.errorText(xhr, " ", true)
         helper.removeClass("hidden").html(err);
         me.animateFail();
         me.trigger("cooper:save:fail");
       });
    },
    
    undo: function()
    {
      var me = this;
      if (me.getValue() == me.undoValue) return;
      
      me.setValue(me.undoValue);
      me.editEnd({undo: true});
    },
    
    render: function()
    {
      var markup = this.compiled({
        me: this, common: common, perm: perm,
      });
                                      
      this.$el.empty().append(markup);
                                                  
      if (this.extraRender) this.extraRender();
                                                               
      markup = this.innerTemplate({me: this, common: common, perm: perm});
      this.$("#input-"+this.serial+"-placeholder").replaceWith( markup );

      var main = this.$(".cooper-main-input");
      if (main.size()) this.$input = main;
    },
    
    animate: function(el, _class)
    {
      el.removeClass(_class)
        .addClass(_class)
        .delay(1000)
        .queue(function()
        {
          $(this).removeClass(_class).dequeue();
        });
    },
    
    animateSuccess: function()
    {
      return this.animate(this.$input,"animate_success");
    },

    animateFail: function()
    {
      return this.animate(this.$input,"animate_fail");
    },
                                                                        
    clearAnimation: function()
    {
      this.$input.css("animation", ""); this.$input.css("-webkit-animation", "");
    },
    
    getValue: function()
    {
      return this.$input.val();
    },
    
    setValue: function(val)
    {
      this.$input.val(val);
    },
                                                                                                                
  });
  
  o.Select = o._Generic.extend({
    events: {
      "focus select": "editBegin",
      "change select": "editEnd",
    },
    
    template: selectTpl,
  });
  
  o.Checkbox = o._Generic.extend({
    events: {
      "focus input": "editBegin",
      "change input": "editEnd",
    },

    template: checkboxTpl,
    
    getValue: function()
    {
      return this.$input.is(':checked');
    },
    
    setValue: function(val)
    {
      this.$input.prop("checked",!!val);
    },
  });
  
  o.Input = o._Generic.extend({
    events: {
      "focus input": "editBegin",
      "blur input": "editEnd",
      "keyup input": ev => { if (ev.keyCode==13) ev.target.blur() },
    },
    
    template: inputTpl,
  });

  o.Textarea = o._Generic.extend({
    events: {
      "focus textarea": "editBegin",
      "blur textarea": "editEnd",
    },
    
    template: textareaTpl,
  });

  return o;
});
