define([
  'jquery',
  'underscore',
  'backboneRadix',
  'common',
  "permission",
  
  'common/cooper/cooper',
  
], function($, _, Backbone, common, perm,
            cooper)
{
  return Backbone.View.extend({
  
    initialize: function(options)
    {
      var me = this;

      me.views = [];
      
      _.extend(this, _.pick(options, "rowTemplate", "tableTemplate", "extraRender", "module"));
      this.compiled = _.template(this.tableTemplate,null);
      
      var markup = this.compiled({me: me});
      this.$el.empty().append(markup);

      this.extraRender = options.extraRender;
    },
    
    render: function()
    {
      var me = this;
      me.removeDependentViews();
      
      var rowtpl = _.template(this.rowTemplate);
      var table = me.$("table tbody");
      table.empty();
      
      me.collection.each(model =>
      {
        var tr = $("<tr>"+
          rowtpl({me: me, model: model, common: common, perm: perm, module: me.module})
          +"</tr>");
        table.append(tr);
        
        tr.find("cooper-select,cooper-checkbox,cooper-input,cooper-textarea").each((i,el) => {
          var attr = _.chain(el.attributes)
                     .map(el => [
                       el.name, 
                       /\{/.test(el.nodeValue) ? eval(el.nodeValue) : el.nodeValue
                     ])
                     .object()
                     .value();
          attr.model = model;
          attr.el = $(el).parent();
          
          var v;
          
          switch(el.tagName)
          {
            case "COOPER-SELECT": v = new cooper.Select(attr); break;
            case "COOPER-CHECKBOX": v = new cooper.Checkbox(attr); break;
            case "COOPER-INPUT": v = new cooper.Input(attr); break;
            case "COOPER-TEXTAREA": v = new cooper.Textarea(attr); break;
          }
          v.render();
          me.views.push(v);
        });
      });

      if (me.extraRender) me.extraRender();
    },
    
    removeDependentViews: function()
    {
      this.views.forEach(v => v.remove());
      this.views = [];
    },
  });
});
