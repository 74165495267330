define([
  'common',
  'jquery',
  'underscore',
  'common/visual/oneof',
  'navigation',
  'text-loader!common/visual/overlay.tpl',
], function(common,$, _, OneOf, nav, template)
{
  return Object.create(OneOf).extend({

    layout: {},
    template: template,

    create: function(el,opt)
    {
      var me = this;
      return OneOf.create.apply(me,arguments);
    },
    
    ///////////////////////////////////////////////////////
    
    setState: function(state)
    {
      var me = this;
      
      if (_.isEqual(me.state,state)) return;
      me.state = state;
      //console.log("rendering overlay",me.name,me.state);                  
      me._ovlSetState();
    },

    _ovlSetState: function(name)
    {
      var me = this;

      var needs = me.state[me.name];
      if (!needs)
      {
        needs = _.isFunction(me.neededOverlay) ? me.neededOverlay(me.state, me.name) : "";
      }

      var rec = me.findModule(needs) || me.findByRoute() || me.layout[0];

      me.currentModIndex = common.findKeyWhere(me.layout, r => r==rec);

      nav.moduleFromPath(rec).then( () =>
      {
        var newMod = rec.module;
        needs = needs || rec.name || newMod.name;

        var old = me.current;
        me.current = needs;

        if (old && old!=needs)
        {
          if (old) me.findModule(old).module.detach();
        }
      
        me.state[me.name] = newMod.name;

        var cont = me.$el.find("#"+me.name+"-"+needs+"");
        if (!cont.length) 
        {
          throw new Error("No container found: #"+me.name+"-"+needs);
        }

        nav.promiseCreateModule(newMod, me.$("#"+me.name+"-"+needs), me.modParams)
          .then(function()
	  {
            if (newMod.initialized && old && old!=needs && newMod.detached) newMod.attach();

            newMod.setState(me.state);
          });
      })
      .catch(err => console.log(err));
    },
    
    findModule: function(name)
    {
      return _.find(this.layout, el => (el.name||el.module.name)==name);
    },
    
    findByRoute: function()
    {
      var me = this;

      return _.find(me.layout, el => 
      {
        if (!el.route) return false;
        return new RegExp("^/"+el.route+"$").test(me.state._rest);
      });
    },

  }); // object
});
