var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Select", {
    attrs: {
      options: _vm.options,
      name: _vm.name,
      label: _vm.label,
      size: _vm.size,
      labelSize: _vm.labelSize,
      info: _vm.info,
      disabled: _vm.disabled,
      apiDomain: _vm.apiDomain,
      apiTopic: _vm.apiTopic,
      apiPath: _vm.apiPath,
      value: _vm.value
    },
    on: { input: _vm.input }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }