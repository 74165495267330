var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !this.url,
          expression: "!this.url"
        }
      ],
      staticClass:
        "glyphicon glyphicon-play jpm-play cursor-pointer row-play-pause",
      on: { click: _vm.play }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.url,
            expression: "this.url"
          }
        ],
        staticStyle: { display: "flex", "align-items": "center" }
      },
      [
        _c("span", { attrs: { title: "Ускореное воспроизведение" } }, [
          _vm._v("2x "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.rate,
                expression: "rate"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.rate)
                ? _vm._i(_vm.rate, null) > -1
                : _vm.rate
            },
            on: {
              change: [
                function($event) {
                  var $$a = _vm.rate,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.rate = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.rate = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.rate = $$c
                  }
                },
                _vm.speed
              ]
            }
          })
        ]),
        _vm._v(" "),
        _c("audio", {
          staticStyle: { width: "100%" },
          attrs: {
            controls: "",
            preload: "none",
            autoplay: "autoplay",
            src: _vm.url
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }